////////////////////////////////////////////////////////////////////////////////////////////////////

// HEADER.
.Header {
  position: fixed;
  width: $nano-header-width;
  height: $nano-header-height-mobile;
  z-index: 999;
  top: 0;
  background: $nano-header-background-color;
  box-shadow: $nano-header-box-shadox-color 0px -1px 0px 0px inset;
  color: $nano-header-font-color;

  @include vendor-prefix ('transition', 'all .25s ease');
  @include vendor-prefix ('backdrop-filter', 'blur(6px)');

  .Header__Wrapper {
    position: relative;
    max-width: $nano-header-wrapper-width;
    max-height: $nano-header-height-mobile;
    margin: 0 auto;
    padding: 13px $nano-space-md;

    @media (min-width: $nano-md-breakpoint) {
      max-height: $nano-header-height-desktop;
      padding: 18px $nano-space-md;
    }

    @media (min-width: $nano-lg-breakpoint) { padding: 18px 50px; }

  }

  @media (min-width: $nano-md-breakpoint) { height: $nano-header-height-desktop; }

}

// HEADER LOGO.
.Header .Header__Wrapper .Header__FlexItem.Header__FlexItem--logo {
  display: table;

  img {
    width: auto;
    max-height: 30px;

    @include vendor-prefix ('transform', 'translateY(2.25px)');
  }

  @media (min-width: $nano-md-breakpoint) { display: inline-block; }
}

////////////////////////////////////////////////////////////////////////////////////////////////////

// 'SIGN IN' CTA.
.headerCTA {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 20px;

  @include vendor-prefix ('transform', 'translateY(-50%)');

  .signInBtn { @extend %nano-header-button; }

  @media (min-width: $nano-lg-breakpoint) { right: 50px; }

}

////////////////////////////////////////////////////////////////////////////////////////////////////
