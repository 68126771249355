////////////////////////////////////////////////////////////////////////////////////////////////////

.NANO_PageNotFound {
  width: 100%;
  height: 100%;
  position: relative;
}

////////////////////////////////////////////////////////////////////////////////////////////////////

.NANO_PageNotFoundFloating {
  position: relative;
  top: 50%;
  left: 50%;

  @include vendor-prefix ('transform', 'translate(-50%, -50%)');

  display: table;
  min-width: 82.5%;
  background: url('https://nano-home.github.io/nano/assets/brand/Page-Not-Found-Bk.svg');
  background-repeat: no-repeat;
  background-size: cover;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  @media (min-width: $nano-sm-breakpoint) { min-width: 450px; }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

.NANO_CenteredContent {
  display: table-cell;
  min-width: 85%;
  text-align: center;
  vertical-align: middle;
  position: absolute;
  top: 50%;
  left: 50%;

  @include vendor-prefix ('transform', 'translate(-50%, -50%)');
}

// 'PAGE NOT FOUND' ICON.
.NANO_PageNotFound img {
  width: 100px;
  height: auto;
  display: table;
  margin: 0 auto $nano-space-sm auto;
}

// TITLE.
.NANO_PageNotFound h1 {
  margin: 0 0 $nano-space-md 0;
  padding: 0 $nano-space-md;
  font-size: $nano-font-size-base;
  text-align: center;

  @media (min-width: $nano-sm-breakpoint) {
    font-size: 1.25rem;
    margin: 0 0 $nano-space-lg 0;
  }

}

// 'GO BACK' BUTTON.
.NANO_PageNotFound a {
  @extend %nano-button;
  @include primary-button($nano-primary-color, $nano-white-color);

  display: table;
  margin: 0 auto;
}

////////////////////////////////////////////////////////////////////////////////////////////////////
