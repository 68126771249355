////////////////////////////////////////////////////////////////////////////////////////////////////

.dashboard-block .form-group {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: $nano-space-md;

  &.no-padding { padding: $nano-space-md 0; }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

.profileView {
  display: grid !important;
  grid-template-columns: 1fr;
  padding: $nano-space-md 0;

  > div { padding: 15px $nano-space-md; }

  @media (min-width: $nano-md-breakpoint) { grid-template-columns: repeat(2, 1fr); }

  @media (min-width: $nano-xl-breakpoint) { grid-template-columns: repeat(3, 1fr); }
}

////////////////////////////////////////////////////////////////////////////////////////////////////

// FORM LABELS.
.dashboard-block .form-group label {
  @extend %nano-labels;

  display: block;
}

////////////////////////////////////////////////////////////////////////////////////////////////////

// FORM CONTROLS.
.dashboard-block .editProfileView {
  input[type="text"], input[type="email"], input[type="number"], input[type="tel"], select {
    @extend %nano-inputs;

    font-size: $nano-font-size-md !important;
    text-align: left !important;
    display: inline-block;
    padding: $nano-space-sm 40px $nano-space-sm $nano-space-md !important;
  }
}

// SELECT.
.dashboard-block .editProfileView select {
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, $nano-border-color, $nano-border-color) !important;
  background-position:
    calc(100% - 20px) calc(1em + 7.5px),
    calc(100% - 15px) calc(1em + 7.5px),
    calc(100% - 2.5em) .825rem !important;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em !important;
  background-repeat: no-repeat !important;
}

////////////////////////////////////////////////////////////////////////////////////////////////////

// CHECKBOXES.
.dashboard-block .form-group input[type="checkbox"] {
  @include vendor-prefix ('transform', 'translateY(1.5px)');

  @media (max-width: $nano-sm-breakpoint) { margin: 0 0 0 calc(50% - 115px); }
}

////////////////////////////////////////////////////////////////////////////////////////////////////

.editProfileActions {
  padding: $nano-space-md;

  // 'CANCEL' & 'SAVE' CHANGES BTN.
  $actionButtons : ("cancelChangesBtn" , $nano-dashboard-profile-cancel-button-background-color , $nano-dashboard-profile-cancel-button-font-color , 0 $nano-space-sm 0 0 , 0 0 15px 0),
                   ("saveChangesBtn" , $nano-primary-color , $nano-white-color , 0 , 0);

  @each $btnClass, $btnBackground , $btnColor , $btnMarginDesktop, $btnMarginMobile in $actionButtons {
    .#{$btnClass} {
      @extend %nano-button;
      @include primary-button($btnBackground, $btnColor);

      width: 100%;
      margin: #{$btnMarginMobile};

      @media (min-width: $nano-md-breakpoint) {
        width: auto;
        margin: #{$btnMarginDesktop};
      }
    }
  }

  @media (min-width: $nano-md-breakpoint) { text-align: right; }
}

////////////////////////////////////////////////////////////////////////////////////////////////////

// 'EDIT PROFILE' BTN.
.editProfileBtn {
  @extend %dashboard-edit-profile-button;

  position: absolute;
  top: 50%;
  right: 32.5px;

  @include vendor-prefix ('transform', 'translateY(-50%)');
}

////////////////////////////////////////////////////////////////////////////////////////////////////

.editProfileView {
  display: grid !important;
  grid-template-columns: 1fr;
  row-gap: 20px;

  .editProfileCol { position: relative; }

  @media (min-width: $nano-md-breakpoint) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
  }

  @media (min-width: $nano-xl-breakpoint) { grid-template-columns: repeat(3, 1fr); }

}

.editProfileCol {
  input:not([disabled]), select:not([disabled]) {
    background-color: rgba(222, 222, 222, .275) !important;

    // &:not(:focus-visible) { border-color: $nano-dashboard-profile-edit-input-border-color !important; }
  }
}

// ERROR STATUS.
.editProfileCol.field-invalid {

  label { color: $nano-dashboard-profile-error-accent-color; }

  // ERROR ACCENT COLOR.
  input:not([disabled]), select:not([disabled]) {
    border-width: 2px;
    border-color: $nano-dashboard-profile-error-accent-color !important;
    background-color: $nano-dashboard-profile-error-accent-color-light !important;
  }

  // ERROR TOOLTIP.
  .nano-tooltip {
    position: absolute;
    right: 10px;
    bottom: 12.5px;

    // ICON.
    i {
      font-size: $nano-font-size-xl;
      line-height: 1.25rem;
      color: $nano-dashboard-profile-error-accent-color;
    }

    @media (min-width: $nano-sm-breakpoint) { bottom: 14px; }

  }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

// GOOGLE PLACES - SUGGESTIONS DROPDOWN.
.editProfileView .suggestionsGooglePlaces {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 77.5px;
  z-index: 999;
  list-style-type: none;
  background: $nano-white-color;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid $nano-border-color;
  box-shadow: 0 6px 5px -2px #AEAEAE;

  li {
    font-family: $nano-primary-font-family;
    font-size: $nano-font-size-sm;
    font-weight: $nano-font-weight-medium;
    color: $nano-secondary-color;
    text-align: left;
    padding: 10px $nano-space-xs;
    border-bottom: 1px solid $nano-border-color;
    opacity: .85;

    @include vendor-prefix ('transition', 'all .125s ease');

    strong { font-family: $nano-heading-font-family; }

    &:last-child { border-bottom: none; }

    @media (min-width: $nano-sm-breakpoint) {
      font-size: $nano-font-size-md;

      &:hover {
        cursor: pointer;
        opacity: 1;
        background: $nano-light-gray-color-1;
      }

    }

  }

  @media (min-width: $nano-sm-breakpoint) { top: 80px; }

}

////////////////////////////////////////////////////////////////////////////////////////////////////
