////////////////////////////////////////////////////////////////////////////////////////////////////

@import url('https://cdn.materialdesignicons.com/6.5.95/css/materialdesignicons.min.css');
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700&display=swap');

////////////////////////////////////////////////////////////////////////////////////////////////////

@font-face {
  font-family: 'Roobert Regular';
  src: url('fonts/Roobert-Regular.otf');
}

@font-face {
  font-family: 'Roobert Medium';
  src: url('fonts/Roobert-Medium.otf');
}

@font-face {
  font-family: 'Roobert SemiBold';
  src: url('fonts/Roobert-SemiBold.otf');
}

@font-face {
  font-family: 'Roobert Bold';
  src: url('fonts/Roobert-Bold.otf');
}

////////////////////////////////////////////////////////////////////////////////////////////////////

// $nano-primary-font-family:                                                   'Inter', sans-serif;
$nano-primary-font-family: 'Roobert Regular', sans-serif;
$nano-mdi-icons-font-family: 'Material Design Icons';

////////////////////////////////////////////////////////////////////////////////////////////////////

$nano-heading-font-family: 'Roobert Bold', sans-serif;
$nano-sub-heading-font-family: 'Roobert Medium', sans-serif;
$nano-cta-font-family: 'Roobert Medium', sans-serif;
$nano-shipping-address-inputs-font-family: 'Roobert Medium', sans-serif;
$nano-dashboard-profile-font-family: 'Roobert Bold', sans-serif;
$nano-best-deal-ribbon-font-family: 'Roobert SemiBold', sans-serif;
$nano-profile-complete-button-font-family: 'Roobert SemiBold', sans-serif;
$nano-profile-complete-heading-font-family-1: 'Roobert SemiBold', sans-serif;
$nano-profile-complete-heading-font-family-2: 'Roobert Bold', sans-serif;

////////////////////////////////////////////////////////////////////////////////////////////////////

$nano-font-size-base: 1rem;
$nano-font-size-xs: 0.625rem;
$nano-font-size-sm: 0.75rem;
$nano-font-size-md: 0.875rem;
$nano-font-size-lg: 1.125rem;
$nano-font-size-xl: 1.25rem;
$nano-font-size-xxl: 1.5rem;

////////////////////////////////////////////////////////////////////////////////////////////////////

$nano-font-weight-light: 300;
$nano-font-weight-regular: 400;
$nano-font-weight-medium: 500;
$nano-font-weight-semibold: 600;
$nano-font-weight-bold: 700;

////////////////////////////////////////////////////////////////////////////////////////////////////
