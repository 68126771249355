.portal-background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, .6);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 999;
}

.portal-content {
  width: calc(100% - 50px);
  height: auto;
  padding: 25px;
  background-color: #FFFFFF;
  border-radius: 12.5px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) { padding: 50px; }

  @media (min-width: 992px) { width: 65%; }

  @media (min-width: 1400px) { width: 50%; }

}
