////////////////////////////////////////////////////////////////////////////////////////////////////

.progressWrapper {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  pointer-events: none;

  .progressContainer {
    position: absolute;
    bottom: 10px;
    left: 50%;
    width: 200px;
    height: 10px;
    overflow: hidden;
    box-shadow: 0 1px 2px rgb(9 30 66 / 10%);
    background: $nano-progress-bar-container-background-color;

    @include vendor-prefix ('border-radius', '6px');
    @include vendor-prefix ('transform', 'translateX(-50%)');

    .progressBar {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background: $nano-progress-bar-background-color;

      @include vendor-prefix ('border-radius', '6px');
      @include vendor-prefix ('transition', 'all .25s ease');
    }

  }

  @media (max-width: $nano-md-breakpoint) { display: none; }

}

////////////////////////////////////////////////////////////////////////////////////////////////////
