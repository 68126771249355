////////////////////////////////////////////////////////////////////////////////////////////////////

// DASHBOARD HEADER.
.dashboardHeader {
  position: relative;
  width: $nano-dashboard-header-width;
  max-width: $nano-dashboard-header-max-width-mobile;
  height: $nano-dashboard-header-height-mobile;
  margin: 0 0 25px 0;
  padding: 25px;
  background: $nano-dashboard-header-background-color;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .04);
  z-index: 10;

  .dashboardWrapper {
    display: table;
    width: $nano-dashboard-header-height-desktop;
    height: $nano-dashboard-header-height-desktop;

    .dashboardHeaderContent {
      width: 100%;
      height: 100%;

      @media (max-width: $nano-lg-breakpoint) {
        // display: flex;
        // flex-direction: row;
        // justify-content: space-between;
        // align-items: center;
      }

    }

  }

  @media (min-width: $nano-lg-breakpoint) {
    position: fixed;
    margin: 0;
    padding: 0;
    max-width: $nano-dashboard-header-max-width-desktop;
    height: $nano-dashboard-header-height-desktop;
    box-shadow: none;
  }

}

// NANO LOGO.
.dashboardLogo {
  position: relative;
  display: table;
  width: $nano-dashboard-logo-width;
  height: $nano-dashboard-logo-height;
  padding: 0;
  background-image: url('https://cdn.shopify.com/s/files/1/0611/2120/0349/t/47/assets/logotype-F5A64F.svg?v=3049963328439442714https://cdn.icon-icons.com/icons2/3224/PNG/512/ac_air_conditioning_cold_warm_appliance_icon_196773.png');
  background-size: contain;
  background-repeat: no-repeat;

  @include vendor-prefix ('transition', 'all .25s ease');

  &:after {
    content: attr(data-name);
    position: absolute;
    left: calc(100% + 10px);
    opacity: .75;
    font-size: $nano-font-size-lg;
    font-weight: $nano-font-weight-semibold;
    color: $nano-dashboard-nav-link-color;
    letter-spacing: .25px;
    line-height: 35px;
  }

  @media (max-width: $nano-lg-breakpoint) and (min-width: $nano-sm-breakpoint) { @include vendor-prefix ('transform', 'translateY(1px)'); }

  @media (max-width: $nano-sm-breakpoint) { max-height: 25px; }

  @media (min-width: $nano-lg-breakpoint) { margin: 25px 0 25px 25px; }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

// NAVIGATION.
// .dashboardNav {
//
//   // NAV. LINKS.
//   a {
//     display: block;
//     opacity: .6;
//     cursor: pointer;
//     font-weight: $nano-font-weight-medium;
//     text-decoration: none;
//     margin: $nano-space-md 0;
//     color: $nano-dashboard-nav-link-color;
//
//     @include vendor-prefix ('transition', 'all .25s ease');
//
//     &:hover { opacity: 1; }
//
//     // ACTIVE LINK.
//     &.active {
//       font-weight: $nano-font-weight-bold;
//       color: $nano-dashboard-nav-link-active-color;
//       letter-spacing: .25px;
//       text-decoration: underline;
//       opacity: 1;
//     }
//
//     @media (max-width: $nano-lg-breakpoint) {
//       display: inline-block;
//       margin: 0 0 0 $nano-space-md;
//       font-size: $nano-font-size-md;
//       line-height: 32px;
//
//       &.Profile { display: none; }
//
//     }
//
//     @media (max-width: $nano-sm-breakpoint) {
//       margin: 0 0 0 $nano-space-sm;
//
//       &:first-child { margin: 0; }
//     }
//
//     ////////////////////////////////////////////////////////////////////////////////////////////////////
//
//     // ICONS.
//     @media (min-width: $nano-lg-breakpoint) {
//       $icons: ("Billing": "\F0EE1", "Home": "\F02DC", "Logout": "\F0343", "Profile": "\F0009");
//
//       @each $route, $glyph in $icons {
//         &.#{$route} {
//           &:before {
//             display: inline-block;
//             margin: 0 $nano-space-xs 0 0;
//             transform: translateY(1.5px);
//             font-family: $nano-mdi-icons-font-family;
//             font-size: $nano-font-size-xl;
//             font-weight: $nano-font-weight-medium;
//             content: "#{$glyph}";
//           }
//         }
//       }
//     }
//
//     ////////////////////////////////////////////////////////////////////////////////////////////////////
//
//   }
//
//   @media (min-width: $nano-lg-breakpoint) {
//     position: absolute;
//     top: 50%;
//
//     @include vendor-prefix ('transform', 'translateY(-50%)');
//   }
//
// }

////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////

// NAVIGATION.
.dashboardNav {
  width: 100%;
  max-height: 1px;
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 0;

  @include vendor-prefix ('transition', 'all 1.5s cubic-bezier(.325, 1, .22, 1)');

  @media (min-width: $nano-lg-breakpoint) {
    position: absolute;
    top: 50%;
    padding: 0 0 0 25px;
    overflow: unset;
    max-height: none;

    @include vendor-prefix ('transform', 'translateY(-50%)');
  }

}

// NAV. LINKS.
.dashboardNav a {
  font-size: $nano-font-size-md;
  color: $nano-dashboard-nav-link-color;
  display: block;
  margin: 0;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;

  @media (min-width: $nano-lg-breakpoint) {

    // ICONS.
    $icons: ("Billing": "\F0EE1", "Home": "\F02DC", "Logout": "\F0343", "Profile": "\F0009");

    @each $route, $glyph in $icons {
      &.#{$route} {
        i {
          font-style: normal;
          display: inline-block;

          @include vendor-prefix ('transform', 'translateY(1.5px)');

          &:before {
            display: inline-block;
            margin: 0 $nano-space-xs 0 0;
            font-family: $nano-mdi-icons-font-family;
            font-size: $nano-font-size-xl;
            font-weight: $nano-font-weight-medium;
            content: "#{$glyph}";
          }

        }
      }
    }

  }

  // ACTIVE LINK.
  &.active {
    font-family: $nano-heading-font-family;
    font-weight: $nano-font-weight-bold;
  }

  @media (min-width: $nano-lg-breakpoint) {
    opacity: .625;
    padding: $nano-space-sm 0 $nano-space-sm 25px;

    // ICON ANIMATION ON HOVER.
    &:hover:not(.active) {
      i:before { @include vendor-prefix ('animation', 'swing ease-in-out .5s 1 alternate'); }
    }

    &.active, &:hover {
      color: $nano-dashboard-nav-link-active-color;
      outline: none;
      opacity: 1;
      position: relative;
      background-color: $nano-body-background-color;
      border-top-left-radius: 22.25px;
      border-bottom-left-radius: 22.25px;

      &:after {
        content: "";
        position: absolute;
        background-color: transparent;
        bottom: 44.5px;
        right: 0;
        height: 35px;
        width: 35px;
        border-bottom-right-radius: 18px;
        box-shadow: 0 20px 0 0 $nano-body-background-color;
      }

      &:before {
        content: "";
        position: absolute;
        background-color: transparent;
        top: 44.5px;
        right: 0;
        height: 35px;
        width: 35px;
        border-top-right-radius: 18px;
        box-shadow: 0 -20px 0 0 $nano-body-background-color;
      }

    }

  }

  @media (max-width: $nano-lg-breakpoint) {
    display: block;
    width: 100%;
    padding: 7.5px 0;
    font-size: $nano-font-size-lg;

    &:first-child { padding-top: 27.5px; }
  }

}

// NAV. ICONS ANIMATION.
@keyframes swing {
  0%, 30%, 50%, 70%, 100% { transform: rotate(0deg); }

  10% { transform: rotate(12.5deg); }

  40% { transform: rotate(-12.5deg); }

  60% { transform: rotate(6.25deg); }

  80% { transform: rotate(-6.25deg); }
}

////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////

// MOBILE COLLAPSE NAVIGATION.

// CLOSE ICON.
.navToggleIcon {
  display: block;
  position: absolute;
  top: 28.5px;
  right: 25px;
  width: 27px;
  height: 20px;
  cursor: pointer;
  outline: none;
  text-indent: -9999px;
  border: none;
  color: transparent;
  text-shadow: 0 0 0 gray;
  text-align: center;

  @include vendor-prefix ('transition', 'all 1s cubic-bezier(.19, 1, .22, 1)');

  &:focus { outline: none; }

  .toggleIconLines {
    position: absolute;
    display: block;
    background: $nano-secondary-color;
    width: 27px;
    height: .2rem;
    border-radius: .2rem;
    left: 0;
    text-indent: 0;

    @include vendor-prefix ('transition', 'all .6s cubic-bezier(.5, .1, 0, 1.2)');

    &:nth-child(1) { top: 0; }
    &:nth-child(2) { top: 8px; }
    &:nth-child(3) { top: 16px; }
  }

  @media (min-width: $nano-sm-breakpoint) { top: 30px; }
  @media (min-width: $nano-lg-breakpoint) { display: none; }

}

.dashboardHeader input[type=checkbox] {
  display: none;
  visibility: hidden;

  &:checked {

    ~ .dashboardNav {
      height: auto;
      max-height: 30rem;

      @include vendor-prefix ('transition', 'all 1.25s cubic-bezier(.5, 1, .22, 1)');
    }

    ~ label .toggleIconLines:first-child {
      top: 0px;
      width: 27px;

      @include vendor-prefix ('transform', 'translateX(-8px) rotate(-45deg) translateY(12px)');
    }

    ~ label .toggleIconLines:nth-child(2) {
      opacity: 0;
    }

    ~ label .toggleIconLines:nth-child(3) {
      top: 14px;
      width: 27px;

      @include vendor-prefix ('transform', 'translateX(-5px) rotate(45deg) translateY(-8px)');
    }

  }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

// USER BOX.
.dashboardUserInfoAnchor {
  text-decoration: none;

  @media (max-width: $nano-lg-breakpoint) { display: none; }
}

.dashboardUserInfo {
  position: relative;
  width: $nano-dashboard-user-box-width;
  bottom: 0;
  left: 0;
  margin: $nano-space-md auto 0 auto;
  border: 1px solid $nano-dashboard-border-color;
  box-shadow: 0 2px 5px 0 rgba(189, 208, 223, .5);
  font-size: $nano-font-size-base;

  @include vendor-prefix ('border-radius', '5px');

  .current-user {
    background: $nano-white-color;
    position: relative;
    padding: 15px;
    border-radius: 10px;

    // USER PROFILE PIC.
    .userPhoto {
      position: absolute;
      width: $nano-dashboard-user-img-width;
      height: $nano-dashboard-user-img-height;
      background-size: cover;

      @include vendor-prefix ('border-radius', '100%');
    }

    .userInfo {
      color: $nano-secondary-color;
      text-overflow: ellipsis;
      text-decoration: none;
      white-space: nowrap;
      padding: 0 0 0 55px;
      overflow: hidden;

      // USER NAME.
      &.name {
        font-weight: $nano-font-weight-bold;
        line-height: 22px;
        text-decoration: none;
      }

      // USER EMAIL.
      &.email {
        font-size: $nano-font-size-md;
        line-height: 22px;
        text-decoration: none;
      }

      @media (max-width: $nano-lg-breakpoint) { overflow: hidden; }

    }

  }

  @media (min-width: $nano-lg-breakpoint) {
    position: absolute;
    margin: 0;
    padding: 25px;
    border: none;
    box-shadow: none;
  }

}

////////////////////////////////////////////////////////////////////////////////////////////////////
