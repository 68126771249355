////////////////////////////////////////////////////////////////////////////////////////////////////

$nano-space-unit: 16px;
$nano-space-xxs: calc(0.25 * $nano-space-unit); // 4px
$nano-space-xs: calc(0.5 * $nano-space-unit); // 8px
$nano-space-sm: calc(0.75 * $nano-space-unit); // 12px
$nano-space-md: calc(1.25 * $nano-space-unit); // 20px
$nano-space-lg: calc(2 * $nano-space-unit); // 32px
$nano-space-xl: calc(3.25 * $nano-space-unit); // 52px
$nano-space-xxl: calc(5.25 * $nano-space-unit); // 84px

////////////////////////////////////////////////////////////////////////////////////////////////////

$nano-header-width: 100%;
$nano-header-height-desktop: 70px;
$nano-header-height-mobile: 60px;
$nano-header-wrapper-width: 1316px;

////////////////////////////////////////////////////////////////////////////////////////////////////

$nano-dashboard-header-width: 100%;
$nano-dashboard-header-max-width-desktop: 350px;
$nano-dashboard-header-max-width-mobile: none;
$nano-dashboard-header-height-desktop: 100%;
$nano-dashboard-header-height-mobile: auto;

$nano-dashboard-logo-width: 155px;
$nano-dashboard-logo-height: 30px;

$nano-dashboard-user-box-width: 100%;
$nano-dashboard-user-img-width: 44px;
$nano-dashboard-user-img-height: 44px;

$nano-dashboard-content-width-desktop: 90%;
$nano-dashboard-content-width-mobile: 95%;
$nano-dashboard-content-max-width-desktop: 82.5%;
$nano-dashboard-content-max-width-large-desktop: 1350px;
$nano-dashboard-content-height-desktop: 100%;
$nano-dashboard-content-height-mobile: auto;

////////////////////////////////////////////////////////////////////////////////////////////////////

$nano-steps-input-height-desktop: 50px;
$nano-steps-input-height-mobile: 47.5px;

$nano-steps-checboxes-radio-height-desktop: 45px;
$nano-steps-checboxes-radio-height-mobile: 47.5px;

$nano-dimensions-filter-img-width-desktop: 185px;
$nano-dimensions-filter-img-width-mobile: 125px;

$nano-dimensions-filter-name-height-desktop: 40px;
$nano-dimensions-filter-name-height-mobile: 35px;

$nano-dimensions-filter-row-number-width-desktop: 40px;
$nano-dimensions-filter-row-number-width-mobile: 35px;
$nano-dimensions-filter-row-number-height: 40px;

$nano-dimensions-filter-name-input-width-desktop: calc(100% - 280px);
$nano-dimensions-filter-name-input-width-mobile: 100%;

$nano-dimensions-square-input-width-desktop: 40px;
$nano-dimensions-square-input-width-mobile: calc(100% / 3 - 20px);
$nano-dimensions-square-input-height-desktop: 40px;
$nano-dimensions-square-input-height-mobile: 35px;
$nano-dimensions-square-x-separator-width: 30px;

////////////////////////////////////////////////////////////////////////////////////////////////////
