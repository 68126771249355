////////////////////////////////////////////////////////////////////////////////////////////////////

// DASHBOARD GENERIC BOX-SHADOW.
%dashboard-home-box-shadow {
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 2px,
              rgb(0 0 0 / 5%) 0px 3px 6px;
}

////////////////////////////////////////////////////////////////////////////////////////////////////

// HOME CONTAINER.
.dashboardHomeContainer {
  width: 100%;
  margin: 0 auto;
  padding: 0 0 50px 0;

  @media (min-width: $nano-lg-breakpoint) { padding: 0 25px; }

  @media (min-width: $nano-xl-breakpoint) {
    position: relative;
    top: 50%;

    @include vendor-prefix ('transform', 'translateY(-50%)');
  }

  @media (min-width: $nano-xxxl-breakpoint) { max-width: 1450px; }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

.topInfoContainer {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 15px;
  row-gap: 0;
  margin: 0 0 25px 0;
  padding: 0 25px;

  .topInfoLeftContent {
    margin: 0 0 25px 0;

    @media (min-width: $nano-xl-breakpoint) { margin: 0; }
  }

  @media (min-width: $nano-lg-breakpoint) { padding: 0; }

  @media (min-width: $nano-xl-breakpoint) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 25px;
    row-gap: 25px;
  }

}

// TOP - LEFT CONTENT BOX.
.topInfoContentTop,
.topInfoRightContentTop {
  width: 100%;
  height: 310px;
  margin: 0 0 25px 0;
  background: $nano-white-color;

  @extend %dashboard-home-box-shadow;
  @include vendor-prefix ('border-radius', '15px');

  // HEADER SECTION.
  .contentTopHeader {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 75px;

    @extend %dashboard-home-box-shadow;
    @include vendor-prefix ('border-radius', '15px');

    &:after {
      width: 100%;
      height: 100%;
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      opacity: .0425;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-image: url("https://cdn.shopify.com/s/files/1/0611/2120/0349/files/NANO-Boxes-Header-Pattern-White-01.svg?v=1654622421");
    }

    // 'EDIT' BTN.
    > a {
      @extend %dashboard-home-buttons;
      @include dashboard-buttons ($nano-primary-color, $nano-white-color);

      position: absolute;
      top: 50%;
      right: 18.5px;

      @include vendor-prefix ('transform', 'translateY(-50%)');

      @media (min-width: $nano-sm-breakpoint) { right: 25px; }

    }

    &.grayAccent { background: $nano-dashboard-gray-accent-box-background-color; }
    &.blueAccent { background: $nano-dashboard-blue-accent-box-background-color; }
    &.orangeAccent { background: $nano-dashboard-orange-accent-box-background-color; }

    @media (min-width: $nano-sm-breakpoint) { height: 100px; }

  }

  @media (min-width: $nano-sm-breakpoint) { height: 385px; }

  // FULL HEIGHT.
  &-FullHeight {

    @media (min-width: $nano-sm-breakpoint) {
      height: 585px;
      margin: 0 !important;
    }

  }

}

.topInfoRightContentTop {
  margin: 25px 0 0 0;
  box-shadow: none;
}

// TOP INFO. BOTTOM CONTENT.
.contentTopContent {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 235px;

  // PROFILE INFORMATION.
  .profileInformationBox {
    position: relative;
    top: -37.5px;

    @media (min-width: $nano-sm-breakpoint) { top: -37.5px; }

  }

  &.scrollY { overflow-y: scroll; }

  @media (min-width: $nano-sm-breakpoint) { height: 285px; }

}

// IN CASE FULL HEIGHT.
.topInfoRightContentTop.topInfoRightContentTop-FullHeight .contentTopContent {
  @media (min-width: $nano-sm-breakpoint) { height: 485px; }
}

// USER IMG.
.profilePicture {
  position: relative;
  left: 50%;
  width: 62.5px;
  height: 62.5px;
  background-color: $nano-white-color;
  margin: 0 0 $nano-space-sm 0;

  @include vendor-prefix ('border-radius', '50%');
  @include vendor-prefix ('transform', 'translateX(-50%)');

  // USER IMG.
  &.userImg {
    &:after {
      width: 50px;
      height: 50px;
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-image: url("https://cdn.pixabay.com/photo/2018/11/13/21/43/avatar-3814049_1280.png");

      @include vendor-prefix ('border-radius', '50%');
      @include vendor-prefix ('transform', 'translate(-50%, -50%)');

      @media (min-width: $nano-sm-breakpoint) {
        width: 65px;
        height: 65px;
      }

    }
  }

  @media (min-width: $nano-sm-breakpoint) {
    width: 82.5px;
    height: 82.5px;
  }

  // USER ICON.
  &-userIcon {
    width: 64.5px;
    height: 64.5px;
    background: $nano-dashboard-gray-accent-box-background-color;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 5rem;
      color: $nano-white-color;

      text-shadow: 0 0 2.5px $nano-secondary-color;

      @include vendor-prefix ('transform', 'translate(-50%, -50%)');
    }

  }

}

.contentTopContent .profileInformationBox {

  // USER NAME.
  > h5 {
    font-family: $nano-heading-font-family;
    font-size: $nano-font-size-base;
    font-weight: $nano-font-weight-semibold;
    text-align: center;
    margin: 0 0 8px 0;

    @media (min-width: $nano-xxl-breakpoint) { font-size: $nano-font-size-lg; }

  }

  // INFO.
  .iconBox {

    p {
      font-size: $nano-font-size-xs;
      margin: 0;

      $icons: ("emailIcon", "\F01EE", "right"),
              ("locationIcon", "\F034E", "left"),
              ("homeIcon", "\F0D15", "center"),
              ("squareIcon", "\F0C8F", "center");

      @each $route, $glyph, $alignment in $icons {
        &.#{$route} {
          text-align: center;

          &:before {
            display: inline-block;
            margin: 0 $nano-space-xxs 0 0;
            transform: translateY(2px);
            font-family: $nano-mdi-icons-font-family;
            font-size: $nano-font-size-base;
            font-weight: $nano-font-weight-medium;
            content: "#{$glyph}";

            @media (min-width: $nano-xxl-breakpoint) {
              font-size: $nano-font-size-lg;
              transform: translateY(2.5px);
            }

          }

          @media (min-width: $nano-sm-breakpoint) { text-align: #{$alignment}; }

        }
      }

      @media (min-width: $nano-xxl-breakpoint) { font-size: $nano-font-size-sm; }

    }

    @media (min-width: $nano-sm-breakpoint) {
      &.homeInfo {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 12.5px;
      }

      &.profileEmailAndLocation > div {
        display: inline-block;
        margin: 0 7.5px;
      }
    }

  }

}

// CENTERED CONTENT.
.centeredInfo {
  display: table;
  width: auto;
  margin: 0 auto;
}

.contentTopContent .profileBottomInformation {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: $nano-space-md;
  padding: 0 22.5px;

  @media (min-width: $nano-sm-breakpoint) { grid-template-columns: repeat(3, 1fr); }
}

.contentTopContent .profileBottomInformation > div {
  position: relative;
  padding: 0 $nano-space-xs;
  text-align: center;

  // ICON.
  i {
    display: block;
    margin: 0 0 $nano-space-xs 0;
    font-style: normal;

    $icons: ("clockIcon": "\F0954", "cardIcon": "\F0FEF");

    @each $route, $glyph, $alignment in $icons {
      &.#{$route} {
        &:before {
          display: block;
          font-family: $nano-mdi-icons-font-family;
          font-size: 1.85rem;
          font-weight: $nano-font-weight-medium;
          content: "#{$glyph}";

          @media (min-width: $nano-xxl-breakpoint) { font-size: 2.5rem; }
        }
      }
    }

  }

  img {

    // 'NANO' LOGO.
    &:not(.cardLogo) {
      display: block;
      width: auto;
      height: 28.5px;
      margin: 0 0 $nano-space-xs 0;

      @media (min-width: $nano-xxl-breakpoint) { height: 40px; }
    }

    // CREDIT CARDS LOGO.
    &.cardLogo {
      width: auto;
      height: 22.5px;
      margin: 0 2.5px 0 0;

      @include vendor-prefix ('transform', 'translateY(5.5px)');
    }

  }

  // CAPTION.
  span {
    display: inline-block;
    font-size: $nano-font-size-sm;

    @media (min-width: $nano-xxl-breakpoint) { font-size: $nano-font-size-md; }

    @media (max-width: $nano-sm-breakpoint) { margin: 0 0 $nano-space-xxs 0; }
  }

  // VALUE.
  p {
    font-family: $nano-heading-font-family;
    font-size: $nano-font-size-md;
    font-weight: $nano-font-weight-semibold;
    margin: 0;

    @media (min-width: $nano-sm-breakpoint) { font-size: $nano-font-size-base; }

    @media (min-width: $nano-xxl-breakpoint) { font-size: $nano-font-size-lg; }
  }

  // SEPARATORS LINE.
  &:nth-child(2) {
    @media (min-width: $nano-sm-breakpoint) {
      &:after,
      &:before {
        width: 1px;
        height: 100%;
        content: "";
        position: absolute;
        top: 0;
        background: $nano-border-color;
      }

      &:after { right: -12.5px; }

      &:before { left: -12.5px; }
    }
  }

  &:nth-child(3) {
    @media (max-width: $nano-sm-breakpoint) { display: none; }
  }

  @media (min-width: $nano-sm-breakpoint) {
    text-align: left;
    padding: 0 $nano-space-sm;
  }

}

// TOP INFO - LEFT CONTENT ( BOTTOM ).
.topInfoLeftContentBottom {
  display: grid;
  grid-template-columns: .5fr .5fr;
  column-gap: 15px;

  @media (min-width: $nano-md-breakpoint) {
    grid-template-columns: .5fr 1fr;
    column-gap: 25px;
  }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

// UPGRADE BOXES.
.upgradeOptionsBoxes {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 22.5px;
  // margin: 0 0 25px 0;

  @media (min-width: $nano-sm-breakpoint) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 0;
    column-gap: 15px;

    &-SingleCol { grid-template-columns: repeat(1, 1fr); }
  }

  @media (min-width: $nano-md-breakpoint) { column-gap: 25px; }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

// 'ECO DELIVERY' & 'UPGRADE TO CARBON' COL.
.upgradeOptionsBoxes,
.topInfoLeftContentBottom {
  $upgrades : ("invoicesCol" , "invoicesColContent" , $nano-secondary-color , none , $nano-dashboard-home-invoices-box-background-color , "invoicesAnchor"),
              ("subscriptionPlanCol" , "subscriptionColContent" , $nano-secondary-color , none , $nano-dashboard-home-subscriptions-box-background-color , "subscriptionAnchor"),
              ("ecoDeliveryCol" , "ecoDeliveryColContent" , $nano-dashboard-home-eco-delivery-accent-color , 3.5px solid $nano-dashboard-home-eco-delivery-accent-color , $nano-white-color , "ecoDeliveryAnchor"),
              ("upgradeToCarbonCol" , "upgradeToCarbonColContent" , $nano-secondary-color , 3.5px solid $nano-secondary-color , $nano-white-color , "upgradeToCarbonAnchor"),
              ("completeProfileCol" , "completeProfileColContent" , $nano-dashboard-complete-profile-font-color , 3.5px solid $nano-dashboard-complete-profile-accent-color , $nano-dashboard-complete-profile-background-color , "completeProfileAnchor"),
              ("cancelledSubscriptionCol" , "cancelledSubscriptionColContent" , $nano-dashboard-cancelled-subscription-accent-color , 3.5px solid $nano-dashboard-cancelled-subscription-accent-color , $nano-dashboard-cancelled-subscription-background-color , "cancelledSubscriptionAnchor");

  @each $colClass, $innerColClass, $accentColor, $accentBorder, $backgroundColor, $anchorClass in $upgrades {
    .#{$colClass} {
      height: 125px;
      background: #{$backgroundColor};
      border: #{$accentBorder};
      padding: 15px;

      @extend %dashboard-home-box-shadow;
      @include vendor-prefix ('border-radius', '15px');
      @include vendor-prefix ('transition', 'all .215s ease');

      .#{$innerColClass} {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 100%;

        // ICON.
        i {
          display: inline-block;
          font-size: 3rem;
          color: #{$accentColor};

          @include vendor-prefix ('transform', 'translate(-5.5px, -10.5px)');

          @media (min-width: $nano-sm-breakpoint) { font-size: 3.5rem; }
        }

        // NANO LOGO.
        img {
          width: 40px;
          height: auto;

          @media (min-width: $nano-sm-breakpoint) { width: 50px; }
        }

        // ANCHORS.
        a, p {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          outline: none;
          font-size: $nano-font-size-md;
          text-decoration: none;
          line-height: 1rem;
          margin: 0;
          padding: 0;

          b { font-family: $nano-heading-font-family; }

          &.#{$anchorClass} {
            color: #{$accentColor};

            // ARROW RIGHT ICON.
            &:after {
              position: absolute;
              display: inline-block;
              transform: translateY(-50%);
              right: 0;
              font-family: $nano-mdi-icons-font-family;
              font-size: 1.65rem;
              font-weight: $nano-font-weight-medium;
              content: "\F0054";

              @include vendor-prefix ('transition', 'all .215s ease');

              @media (min-width: $nano-sm-breakpoint) { font-size: 2rem; }

              @media (max-width: $nano-xxl-breakpoint) and (min-width: $nano-xl-breakpoint) { font-size: 1.5rem; }

            }

          }

          @media (min-width: $nano-sm-breakpoint) {
            font-size: $nano-font-size-lg;
            line-height: 1.25rem;
          }

          @media (max-width: $nano-xxl-breakpoint) and (min-width: $nano-xl-breakpoint) { font-size: $nano-font-size-md; }

        }

      }

      @media (min-width: $nano-sm-breakpoint) {
        height: 175px;
        padding: 22.5px;
      }

      @media (min-width: $nano-md-breakpoint) {
        &:active, &:focus, &:hover {
          @include vendor-prefix ('border-radius', '7.5px');
          a:after, p:after { right: -7.5px !important; }
        }
      }

    }
  }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

.homeInformationBox {
  position: absolute;
  top: 50%;
  left: 12.5px;
  transform: translateY(-50%);
  display: grid;
  grid-template-columns: 50px 1.5fr;
  column-gap: 7.5px;
  align-items: center;
  z-index: 9;

  // HOME ICON.
  .homePicture {
    text-align: center;

    i {
      display: inline-block;
      font-size: 2.85rem;
      color: $nano-white-color;
      transform: translateY(-1.5px);

      @media (min-width: $nano-sm-breakpoint) {
        font-size: 3.25rem;
        transform: translateY(-3.5px);
      }
    }

  }

  // HOME INFORMATION.
  .homeInfo {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;

    p {
      font-size: $nano-font-size-sm;
      font-weight: $nano-font-weight-semibold;
      color: $nano-white-color;
      margin: 0;

      $icons: ("homeIcon", "\F0D15"),
              ("squareIcon", "\F0C8F");

      @each $route, $glyph in $icons {
        &.#{$route} {
          text-align: left;

          &:before {
            display: inline-block;
            margin: 0 $nano-space-xxs 0 0;
            transform: translateY(2px);
            font-family: $nano-mdi-icons-font-family;
            font-size: $nano-font-size-md;
            font-weight: $nano-font-weight-medium;
            content: "#{$glyph}";

            @media (min-width: $nano-sm-breakpoint) { font-size: $nano-font-size-base; }

            @media (min-width: $nano-xxl-breakpoint) { font-size: $nano-font-size-lg; }

          }

        }
      }

      &.homeIcon:before { display: none; }

      @media (min-width: $nano-sm-breakpoint) { font-size: $nano-font-size-md; }

    }

    @media (min-width: $nano-sm-breakpoint) { column-gap: 17.5px; }

  }

  @media (min-width: $nano-sm-breakpoint) { left: 17.5px; }

}

// HOME FILTERS INFORMATION SECTION.
.homeBottomInformation {
  display: table;
  width: 100%;
  height: 100%;
}

// WHEN NO FILTER SELECTED.
.noFilterSelected {
  display: grid;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 22.5px;

  p {
    font-size: $nano-font-size-md;
    margin: 0;

    a {
      font-weight: $nano-font-weight-semibold;
      color: $nano-dashboard-complete-profile-accent-color;
    }

    @media (min-width: $nano-sm-breakpoint) { font-size: $nano-font-size-base; }

  }

}

// FILTER INFORMATION ROW.
.homeFilterRow {
  display: grid;
  width: 100%;
  padding: 17.5px 20px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom: 1px solid $nano-border-color;

  > div {
    display: grid;
    grid-template-columns: 52.5px 1fr 65px;
    align-items: center;

    // FILTER IMG.
    img {
      width: 100%;
      height: auto;
    }

    // FILTER INFO.
    p {
      font-family: $nano-heading-font-family;
      font-size: $nano-font-size-md;
      font-weight: $nano-font-weight-bold;
      line-height: .875rem;
      margin: 0;
      padding: 0 0 0 $nano-space-xs;

      span {
        font-family: $nano-primary-font-family;
        font-weight: $nano-font-weight-light;
      }

      @media (min-width: $nano-sm-breakpoint) {
        font-size: $nano-font-size-base;
        line-height: 1.125rem;
      }

    }

    // EDIT CTA.
    a {
      @extend %dashboard-home-buttons;
      @include dashboard-buttons ($nano-primary-color, $nano-white-color);
    }

    @media (min-width: $nano-sm-breakpoint) { grid-template-columns: 80px 1fr 80px; }

  }

  @media (min-width: $nano-sm-breakpoint) { padding: 17.5px; }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

// DYNAMICALLY CALCULATE ROW HEIGHT DEPENDING ON NUMBER OF FILTERS.
// WHEN FILTER QTY. FROM 1 TO 3, DYNAMICALLY CALCULATE THAT HEIGHT VALUE.
// WHEN FILTER QTY. FROM 4 TO 6, FIXED HEIGHT DEPENDING ON SCREEN SIZE.
@for $i from 1 through 6 {
  .homeFilterRow:first-child:nth-last-child(#{$i}),
  .homeFilterRow:first-child:nth-last-child(#{$i}) ~ .homeFilterRow {
    height: calc(100% / $i);

    @if $i == 4 {
      height: calc(235px / 3);

      @media (min-width: $nano-sm-breakpoint) { height: 95px; }
    }

    @else if $i == 5 {
      height: calc(235px / 3);

      @media (min-width: $nano-sm-breakpoint) { height: 95px; }
    }

    @else if $i == 6 {
      height: calc(235px / 3);

      @media (min-width: $nano-sm-breakpoint) { height: 95px; }
    }

  }

  .topInfoRightContentTop.topInfoRightContentTop-FullHeight {
    .homeFilterRow:first-child:nth-last-child(#{$i}),
    .homeFilterRow:first-child:nth-last-child(#{$i}) ~ .homeFilterRow {

      @if $i == 4 {
        @media (min-width: $nano-sm-breakpoint) { height: calc(485px / 3); }
      }

      @else if $i == 5 {
        @media (min-width: $nano-sm-breakpoint) { height: calc(485px / 3); }
      }

      @else if $i == 6 {
        @media (min-width: $nano-sm-breakpoint) { height: calc(485px / 3); }
      }

    }
  }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #DEDEDE;
    box-shadow: 0 0 1px rgba(255,255,255,.5);
}

////////////////////////////////////////////////////////////////////////////////////////////////////

.subscriptionRelatedBoxes {
  display: none;

  @media (min-width: $nano-md-breakpoint) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 25px;
    padding: 0 25px;
  }

  @media (min-width: $nano-lg-breakpoint) { padding: 0; }

  @media (max-width: $nano-xl-breakpoint) and (min-width: $nano-lg-breakpoint) {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 25px;
  }

}

// BLUE BOXES.
.colBox {
  position: relative;
  height: 125px;
  padding: $nano-space-md;
  background: $nano-dashboard-home-subscription-boxes-background-color;

  @extend %dashboard-home-box-shadow;
  @include vendor-prefix ('border-radius', '15px');
}

// COPY CONTENT - LEFT COL.
.colBox .leftCol {
  position: relative;
  display: inline-block;
  width: calc(100% - 60px);
  height: 100%;

  .leftContent {
    position: absolute;
    bottom: 0;

    // CAPTION.
    span {
      font-size: $nano-font-size-sm;
      color: $nano-dashboard-home-subscription-boxes-font-color;
      display: inline-block;

      @media (min-width: $nano-sm-breakpoint) { font-size: $nano-font-size-sm; }

      @media (min-width: $nano-xl-breakpoint) { font-size: $nano-font-size-md; }

      @media (max-width: $nano-xl-breakpoint) and (min-width: $nano-lg-breakpoint) { font-size: $nano-font-size-md; }
    }

    // VALUE.
    p {
      font-family: $nano-heading-font-family;
      font-size: $nano-font-size-xl;
      color: $nano-dashboard-home-subscription-boxes-font-color;
      margin: 0;

      @media (max-width: $nano-xl-breakpoint) and (min-width: $nano-lg-breakpoint) { font-size: $nano-font-size-xl; }
    }

  }

  @media (max-width: $nano-xl-breakpoint) and (min-width: $nano-lg-breakpoint) { width: calc(100% - 40px); }

}

// ICON COL. - RIGHT COL.
.colBox .rightCol {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 100%;

  // ICON.
  i {
    position: absolute;
    top: 50%;
    right: 0;
    margin: 0;
    font-size: 2.75rem;
    color: $nano-dashboard-home-subscription-boxes-font-color;

    @include vendor-prefix ('transform', 'translateY(-50%)');

    @media (min-width: $nano-lg-breakpoint) { font-size: 3.125rem; }
  }

  @media (max-width: $nano-xl-breakpoint) and (min-width: $nano-lg-breakpoint) { width: 40px; }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

// SLICK CAROUSEL.
.sliderContanier {

  .slick-slide {
    .slideCol { @include vendor-prefix ('transition', 'all .25s ease'); }

    &:not(.slick-current) {
      .slideCol { opacity: .25; }
    }
  }

  // SLIDER DOTS.
  .slick-dots { bottom: -35px; }

  @media (min-width: $nano-md-breakpoint) { display: none; }

}

.sliderContanier .colBox {
  margin: 0 7.5px;

  @media (min-width: $nano-sm-breakpoint) { margin: 0 12.5px; }
}

////////////////////////////////////////////////////////////////////////////////////////////////////
