////////////////////////////////////////////////////////////////////////////////////////////////////

@import "colors";
@import "mixins";
@import "spacing";
@import "typography";

////////////////////////////////////////////////////////////////////////////////////////////////////

%nano-inputs {
  font-family: $nano-primary-font-family;
  font-size: $nano-font-size-md;
  font-weight: $nano-font-weight-medium;
  color: $nano-secondary-color;
  text-align: center;

  width: 100%;
  height: $nano-steps-input-height-mobile;
  outline: none;
  padding: $nano-space-sm $nano-space-lg;
  overflow: hidden;
  background: $nano-white-color;
  border-width: 1px;
  border-style: solid;
  border-color: $nano-border-color;
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 2px,
              rgb(0 0 0 / 5%) 0px 3px 6px;

  @include vendor-prefix ('appearance', 'none');
  @include vendor-prefix ('border-radius', '5px');

  &:disabled {
    opacity: .375;
    pointer-events: none;
  }

  &:focus-visible { border-color: $nano-primary-color; }

  &::placeholder { color: $nano-placeholder-color; }

  @media (min-width: $nano-sm-breakpoint) {
    font-size: $nano-font-size-base;
    height: $nano-steps-input-height-desktop;
  }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

%nano-labels {
  font-family: $nano-heading-font-family;
  font-size: $nano-font-size-md;
  font-weight: $nano-font-weight-semibold;
  color: $nano-secondary-color;
  line-height: .875rem;
  text-align: left;
  text-decoration: none;

  display: inline-block;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0 0 $nano-space-sm 0;

  &.editProfileLabel {
    display: block;
    margin: 0 0 $nano-space-xs 0;
  }

}

////////////////////////////////////////////////////////////////////////////////////////////////////
