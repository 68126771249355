////////////////////////////////////////////////////////////////////////////////////////////////////

// SIDEBAR HEADER BUTTON.
.completeAccountHeaderContainer {
  background: $nano-dashboard-banner-background-color;
  width: calc(100% + 30px);
  position: relative;
  left: -15px;
  bottom: -15px;
  padding: 10px 15px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;

  .completeAccountHeaderBtn {
    font-family: $nano-profile-complete-button-font-family;
    font-size: $nano-font-size-md;
    text-decoration: none;
    letter-spacing: .25px;
    color: $nano-dashboard-banner-font-color;
  }

  @media (max-width: $nano-lg-breakpoint) { display: none; }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

.completeAccountBanner {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  max-width: calc(100% - 40px);
  background: $nano-dashboard-banner-background-color;
  border: 1px solid $nano-dashboard-banner-border-color;
  color: $nano-dashboard-banner-font-color;
  position: relative;
  padding: $nano-space-md;
  margin: $nano-space-md auto 0 auto;

  @include vendor-prefix ('border-radius', '.25rem');

  @media (min-width: $nano-sm-breakpoint) {
    grid-template-columns: 1.5fr .725fr;
    padding: $nano-space-sm $nano-space-md;
  }
  @media (min-width: $nano-md-breakpoint) { grid-template-columns: 1.75fr .65fr; }
  @media (min-width: $nano-lg-breakpoint) { grid-template-columns: 1.5fr .725fr; }
  @media (min-width: $nano-xl-breakpoint) { grid-template-columns: 1.75fr .5fr; }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

.completeAccountBanner {

  .bannerLeftContent {
    margin: 0 0 $nano-space-sm 0;

    // BANNER TITLE.
    h3 {
      font-family: $nano-profile-complete-heading-font-family-1;
      font-size: $nano-font-size-lg;
      color: $nano-dashboard-banner-font-color;
      margin: 0 0 $nano-space-xxs 0;

      b {
        font-family: $nano-profile-complete-heading-font-family-2;
        text-decoration: underline;
      }

    }

    // BANNER COPY.
    span {
      font-size: $nano-font-size-md;
      color: $nano-dashboard-banner-font-color;
      display: inline-block;
      margin: 0;

      @media (min-width: $nano-sm-breakpoint) { margin: 0 0 $nano-space-xxs 0; }

    }

    // PENDING INFO LIST.
    ul {
      margin: 0;
      padding: 0 0 0 15px;

      li {
        font-size: $nano-font-size-sm;
        list-style-type: circle;
      }

      @media (max-width: $nano-sm-breakpoint) { display: none; }

    }

    @media (min-width: $nano-sm-breakpoint) {
      margin: 0;
      padding: 0 $nano-space-md 0 0;
    }

  }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

// BUTTON.
.completeAccountBannerBtn {
  @extend %nano-button;
  @include primary-button($nano-dashboard-banner-button-background-color, $nano-dashboard-banner-button-font-color);

  margin: 0;

  @media (min-width: $nano-sm-breakpoint) { float: right; }

  @media (min-width: $nano-lg-breakpoint) {
    // @include vendor-prefix ('animation', 'bounce 2.75s infinite');
  }

}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% { transform: translateY(-50%); }
  40% { transform: translateY(-72.5%); }
  60% { transform: translateY(-62.5%); }
}

@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% { transform: translateY(-50%); }
  40% { transform: translateY(-72.5%); }
  60% { transform: translateY(-62.5%); }
}

@-o-keyframes bounce {
  0%, 20%, 50%, 80%, 100% { transform: translateY(-50%); }
  40% { transform: translateY(-72.5%); }
  60% { transform: translateY(-62.5%); }
}

@keyframes bounce {
	0%, 20%, 50%, 80%, 100% { transform: translateY(-50%); }
	40% { transform: translateY(-72.5%); }
	60% { transform: translateY(-62.5%); }
}

////////////////////////////////////////////////////////////////////////////////////////////////////
