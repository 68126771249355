////////////////////////////////////////////////////////////////////////////////////////////////////

// MODAL TITLE.
.reactivationModal h3 { @include wizard-titles($nano-font-size-xl, $nano-font-size-lg, $nano-font-weight-bold, 1.5rem, 1.5rem, 0 auto $nano-space-md auto, 0 auto $nano-space-md auto, 1, 100%, 100%); }

////////////////////////////////////////////////////////////////////////////////////////////////////

// SUBSCRIPTION DETAILS.
.reactivationSubscriptionsDetails {
  display: grid;
  grid-template-columns: repeat(2, .5fr);
  background: $nano-white-color;
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 2px,
              rgb(0 0 0 / 5%) 0px 3px 6px;

  @include vendor-prefix ('border-radius', '12.5px');

  > div {
    position: relative;
    padding: $nano-space-md $nano-space-sm;
    border-top: 1px solid $nano-border-color;
    border-right: 1px solid $nano-border-color;
    border-bottom: 1px solid $nano-border-color;
    border-left: 1px solid $nano-border-color;
    text-align: center;

    // ICON.
    i {
      font-size: 2.25rem;
      line-height: 2.25rem;
      display: inline-block;
      margin: 0 0 $nano-space-xs 0;
    }

    // CAPTION.
    p {
      font-size: $nano-font-size-base;
      line-height: 1.125rem;
      margin: 0;
      padding: 0;

      b {
        display: inline-block;
        margin: 0 0 $nano-space-xxs 0;
        font-family: $nano-heading-font-family;
      }

    }

    @media (min-width: $nano-sm-breakpoint) { padding: $nano-space-md; }

  }

  @media (min-width: $nano-md-breakpoint) { grid-template-columns: repeat(4, 1fr); }

}

.reactivationSubscriptionsDetails > div {

  // 'AMOUNT' COL.
  &.amountCol {
    color: $nano-white-color;
    background: $nano-primary-color;
    border-top-left-radius: 12.5px;

    // NANO BACKGROUND PATTERN.
    &:after {
      width: 100%;
      height: 100%;
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      opacity: .0875;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-image: url("https://cdn.shopify.com/s/files/1/0611/2120/0349/files/NANO-Boxes-Header-Pattern-White-01.svg?v=1654622421");
    }

    @media (min-width: $nano-md-breakpoint) { border-bottom-left-radius: 12.5px; }

  }

  // 'FILTERS' COL.
  &.filtersCol {
    @media (max-width: $nano-md-breakpoint) { border-top-right-radius: 12.5px; }
  }

  // 'DELIVERY INTERVAL' COL.
  &.intervalCol {
    @media (max-width: $nano-md-breakpoint) { border-bottom-left-radius: 12.5px; }
  }

  // 'PAYMENT' COL.
  &.paymentCol {
    border-bottom-right-radius: 12.5px;

    @media (min-width: $nano-md-breakpoint) { border-top-right-radius: 12.5px; }
  }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

// FILTERS DETAILS.
.reactivationFiltersDetails {
  padding: $nano-space-lg 0;

  // SUBTITLE.
  h4 {
    text-align: center;
    margin: 0 0 $nano-space-xs 0;
  }

  // FILTERS LIST.
  ul {
    display: table;
    margin: 0 auto;
    padding: 0;
    list-style-type: none;

    li {
      font-size: $nano-font-size-md;
      line-height: 1.5rem;
    }

  }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

// REACTIVATE ACTIONS.
.reactivationActions {
  text-align: center;

  // CAPTION MSG.
  span {
    display: block;
    margin: 0 0 $nano-space-sm 0;
    font-size: $nano-font-size-sm;
    font-style: italic;
  }

  // CTA.
  button {
    @extend %nano-button;

    // 'CANCEL' BTN.
    &.cancelBtn { @include primary-button($nano-white-color, $nano-secondary-color); }

    // 'CHANGE FILTERS' BTN.
    &.changeFiltersBtn { @include primary-button($nano-secondary-color, $nano-white-color); }

    // 'CONFIRM' BTN.
    &.confirmBtn { @include primary-button($nano-primary-color, $nano-white-color); }

    @media (min-width: $nano-sm-breakpoint) { margin: 0 $nano-space-xxs; }

    @media (max-width: $nano-sm-breakpoint) {
      width: 100%;
      margin: 7.5px 0;
    }

  }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

// SPINNER SETTINGS.
.loading-container {
  width: inherit;
  height: inherit;
  position: absolute;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, .8);
}

////////////////////////////////////////////////////////////////////////////////////////////////////
