////////////////////////////////////////////////////////////////////////////////////////////////////

@import "colors";
@import "mixins";
@import "spacing";
@import "typography";

////////////////////////////////////////////////////////////////////////////////////////////////////

%nano-button {
  cursor: pointer;
  position: relative;
  display: inline-block;
  min-width: 145px;
  outline: 1px solid transparent;
  border: 1px solid $nano-border-color;
  padding: $nano-space-sm $nano-space-md;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .1);

  font-family: $nano-cta-font-family;
  font-size: $nano-font-size-base;
  font-weight: $nano-font-weight-semibold;
  text-transform: capitalize;
  text-decoration: none;
  text-align: center;
  line-height: 1;

  @include vendor-prefix ('appearance', 'none');
  @include vendor-prefix ('border-radius', '5px');
  @include vendor-prefix ('transition', 'all .25s ease');

  // 'NEXT' BUTTON.
  &.next { margin: 0 0 0 $nano-space-xs; }

  // 'BACK' BUTTON.
  &.back {
    margin: 0 0 0 0;

    &:before {
      top: 50%;
      left: 22.5px;
      position: absolute;
      transform: translateY(-50%);
      content: "\F004D";
      font-family: $nano-mdi-icons-font-family;
      font-weight: $nano-font-weight-regular;

      @include vendor-prefix ('transition', 'all .25s ease');
    }

    @media (min-width: $nano-md-breakpoint) {
      &:hover {
        &:before { left: 17.5px; }
      }
    }
  }

  &:focus-visible { outline: 1px solid $nano-outline-color; }

  &:disabled, &[disabled] {
    cursor: not-allowed;
    color: $nano-btn-disabled-font-color !important;
    background: $nano-btn-disabled-background-color !important;
    border: 1px solid $nano-btn-disabled-border-color;
    pointer-events: none;
  }

  @media (min-width: $nano-sm-breakpoint) { margin: $nano-space-lg $nano-space-sm 0; }

}

@mixin primary-button ($background-color, $font-color) {
  color: $font-color;
  background: $background-color;

  &:active, &:focus, &:hover { background: darken($background-color, 5); }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

%nano-header-button {
  cursor: pointer;
  position: relative;
  display: inline-block;
  outline: none;
  background: $nano-primary-color;
  border: none;
  margin: 0;
  padding: 0 $nano-space-lg 0 $nano-space-sm;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .1);

  font-family: $nano-cta-font-family;
  font-size: $nano-font-size-md;
  font-weight: $nano-font-weight-semibold;
  color: $nano-white-color;
  text-decoration: none;
  text-align: center;
  line-height: 37.5px;

  @include vendor-prefix ('appearance', 'none');
  @include vendor-prefix ('border-radius', '6px');
  @include vendor-prefix ('transition', 'all .25s ease');

  &:disabled, &[disabled] {
    cursor: not-allowed;
    color: $nano-btn-disabled-font-color !important;
    background: $nano-btn-disabled-background-color !important;
    border: none;
  }

  &:active, &:focus, &:hover { background: darken($nano-primary-color, 5); }

  // ARROW ICON.
  &:after {
    top: 50%;
    right: 12px;
    position: absolute;
    transform: translateY(-50%);
    content: "\F0054";
    font-family: $nano-mdi-icons-font-family;
    font-size: $nano-font-size-base;
    font-weight: $nano-font-weight-regular;

    @include vendor-prefix ('transition', 'all .25s ease');
  }

  @media (min-width: $nano-sm-breakpoint) { min-width: 70px; }

  @media (min-width: $nano-md-breakpoint) {
    line-height: 45px;

    &:hover {
      &:after { right: 8px; }
    }

  }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

%nano-dashboard-button {
  font-family: $nano-cta-font-family;
  font-size: $nano-font-size-md;
  font-weight: $nano-font-weight-medium;
  line-height: $nano-space-md;
  text-decoration: none;
  display: inline-block;
  position: relative;
  border: 0 none;
  outline: 0 none;
  background: $nano-dashboard-content-block-button-background-color;
  margin: 0 $nano-space-md $nano-space-md;
  padding: $nano-space-xxs $nano-space-xs;
  cursor: pointer;
  user-select: none;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, .08) 0px 1px 1px 0px, rgba(60, 66, 87, .08) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, .08) 0px 2px 5px 0px;

  @include vendor-prefix ('appearance', 'none');
  @include vendor-prefix ('border-radius', '5px');
  @include vendor-prefix ('transition', 'all 0.25s ease');

  &:active, &:focus, &:hover {
    background: $nano-dashboard-content-block-button-background-color-hover;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, .08) 0px 1px 1px 0px, rgba(60, 66, 87, .08) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, .08) 0px 3px 9px 0px, rgba(60, 66, 87, .08) 0px 2px 5px 0px;
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////

%nano-skip-step-button {
  position: relative;
  display: table;
  outline: none;
  margin: $nano-space-sm auto 0 auto;
  padding: $nano-space-xs $nano-space-sm;
  font-family: $nano-heading-font-family;
  font-size: $nano-font-size-md;
  color: $nano-primary-color;
  text-decoration: none;

  // ARROW RIGHT ICON.
  &:after {
    top: 50%;
    right: -15px;
    position: absolute;
    transform: translateY(-50%);
    content: "\F0054";
    font-family: $nano-mdi-icons-font-family;
    font-weight: $nano-font-weight-semibold;

    @include vendor-prefix ('transition', 'all .25s ease');
  }

  @media (min-width: $nano-md-breakpoint) {
    &:hover {
      &:after { right: -17.5px; }
    }
  }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

%dashboard-home-buttons {
  cursor: pointer;
  position: relative;
  display: inline-block;
  min-width: 65px;
  outline: none;
  margin: 0;
  padding: $nano-space-xs $nano-space-sm;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
  z-index: 999;

  font-family: $nano-dashboard-profile-font-family;
  font-size: $nano-font-size-sm;
  font-weight: $nano-font-weight-semibold;
  text-decoration: none;
  text-align: center;
  line-height: 1;

  @include vendor-prefix ('appearance', 'none');
  @include vendor-prefix ('border-radius', '7.5px');
  @include vendor-prefix ('transition', 'all .35s ease');

  &:focus-visible { outline: 1px solid $nano-outline-color; }

  @media (min-width: $nano-sm-breakpoint) { min-width: 80px; }

}

@mixin dashboard-buttons ($background-color, $font-color) {
  color: $font-color;
  background: $background-color;

  @media (min-width: $nano-md-breakpoint) {
    &:active, &:focus, &:hover { background: darken($background-color, 5); }
  }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

%dashboard-edit-profile-button {
  cursor: pointer;
  position: relative;
  outline: none;
  background: none;
  border: none;
  box-shadow: none;
  margin: 0;
  padding: $nano-space-xs $nano-space-sm;

  font-family: $nano-heading-font-family;
  font-size: $nano-font-size-md;
  color: $nano-primary-color;
  text-decoration: none;

  @include vendor-prefix ('appearance', 'none');
  @include vendor-prefix ('transition', 'all .25s ease');

  // ARROW RIGHT ICON.
  &:after {
    top: 50%;
    right: -12.5px;
    position: absolute;
    transform: translateY(-50%);
    content: "\F0054";
    font-family: $nano-mdi-icons-font-family;
    font-size: $nano-font-size-lg;
    font-weight: $nano-font-weight-semibold;

    @include vendor-prefix ('transition', 'all .25s ease');
  }

  @media (min-width: $nano-md-breakpoint) {
    &:hover {
      &:after { right: -17.5px; }
    }
  }

}

////////////////////////////////////////////////////////////////////////////////////////////////////
