////////////////////////////////////////////////////////////////////////////////////////////////////

#admin-bar-iframe,
#preview-bar-iframe {
  display: none !important;
  max-width: 780px !important;
  left: 50% !important;
  transform: translateX(-50%) translateY(0) !important;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15) !important;
  background: rgba(255, 255, 255, 0.8);
  bottom: 10px !important;

  @include vendor-prefix('border-radius', '4px');
}

////////////////////////////////////////////////////////////////////////////////////////////////////

// Buttons
.primary-button {
  cursor: pointer;
  color: #FFFFFF;
  background: $nano-primary-color;
  outline: 1px solid transparent;
  border: 1px solid #DDDDDD;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  padding: 12px 20px;
  font-family: "Roobert Medium", sans-serif;
  font-size: 1rem;
  font-weight: 600;
}

.secondary-button {
  cursor: pointer;
  color: #3C4858;
  background: #FFFFFF;
  outline: 1px solid transparent;
  border: 1px solid #dddddd;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  padding: 12px 20px;
  font-family: "Roobert Medium", sans-serif;
  font-size: 1rem;
  font-weight: 600;
}
////////////////////////////////////////////////////////////////////////////////////////////////////

// STEPS FLOATING CONTAINER.
main {
  display: table;
  width: 90%;
  height: 100%;
  position: relative;
  top: $nano-header-height-mobile;
  left: 50%;
  transform: translateX(-50%);
  max-height: calc(100vh - $nano-header-height-mobile);

  .table {
    width: 100%;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 25px 0;

    .middle {
      text-align: center;
    }

    @media (min-width: $nano-sm-breakpoint) {
      padding: 25px;
      overflow: hidden;
    }

    @media (min-width: $nano-md-breakpoint) {
      width: 700px;
    }
  }

  .start.active,
  .start.active .middle {
    display: block;
    overflow: initial;
  }

  @media (min-width: $nano-sm-breakpoint) {
    width: 85%;
  }
  @media (min-width: $nano-md-breakpoint) {
    width: auto;
    top: $nano-header-height-desktop;
    max-height: calc(100vh - $nano-header-height-desktop);
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////

// WIZARD CONTAINER.
.wizardContent {
  display: table;
  width: 100%;
  height: 100%;
}

////////////////////////////////////////////////////////////////////////////////////////////////////

.start {
  // STEP HEADING.
  h3 {
    @include wizard-titles(
      $nano-font-size-xl,
      $nano-font-size-lg,
      $nano-font-weight-bold,
      1.5rem,
      1.5rem,
      0 auto $nano-space-xs auto,
      0 auto $nano-space-sm auto,
      1,
      100%,
      325px
    );

    @media (max-width: $nano-sm-breakpoint) {
      max-width: 285px;
    }
  }

  // STEP SUBHEADING.
  h5 {
    @include wizard-titles(
      $nano-font-size-base,
      $nano-font-size-md,
      $nano-font-weight-regular,
      1.5rem,
      1.5rem,
      0 auto $nano-space-md auto,
      0 auto $nano-space-sm auto,
      0.75,
      100%,
      465px
    );
  }

  // STEP - 'NAME' ACCENT.
  strong {
    font-weight: $nano-font-weight-bold;
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////

// TEXT INPUTS.
.start .input {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 380px;

  input {
    @extend %nano-inputs;
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////

// STEPS BUTTONS.
.buttons-footer {
  display: table;
  margin: $nano-space-md auto 0 auto;

  .button {
    @extend %nano-button;

    .mdi-spin {
      display: none;
    }

    &.processing .mdi-spin {
      display: inline-block;
      margin-right: 10px;
    }

    &.back {
      @include primary-button($nano-white-color, $nano-secondary-color);
    }

    &.next {
      @include primary-button($nano-primary-color, $nano-white-color);
    }
  }
}

.add-filter-button {
  @extend %nano-button;
  @include primary-button($nano-primary-color, $nano-white-color);
  min-width: fit-content;
  margin: 10px;
  padding: 7.5px;
  border-radius: 20px;
}

.remove-filter-button {
  @extend %nano-button;
  @include primary-button($nano-error-color, $nano-white-color);
  min-width: fit-content;
  margin: 10px;
  padding: 5px;
  border-radius: 20px;
}

.add-filter-row {
  width: 100%;
}

// 'SKIP' BUTTON.
.buttons-footer a {
  @extend %nano-skip-step-button;
}

////////////////////////////////////////////////////////////////////////////////////////////////////

/// FILTER ELEMENT
.filter-element-container {
  display: flex;
  flex-wrap: wrap;
  align-content: center;

  img {
    width: 150px;
    height: 150px;
  }

  div {
    h3 {
      font-size: 1rem;
      margin: 0 !important;
    }
  }
}
////////////////////////////////////////////////////////////////////////////////////////////////////

/// CAROUSEL
.carousel-container {
  display: inline-block;
}

.carousel {
  width: 330px;
  text-align: center;
  overflow: hidden;

  .items {
    display: flex;
    padding-bottom: 2px;
    column-gap: 15px;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #DDDDDD;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $nano-primary-color;
    }

    .carousel-item {
      scroll-snap-align: center;
      transform-origin: center center;
      transform: scale(1);
      transition: transform 0.5s;
      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 100px;

      &:nth-child(1) {
        margin: 0 0 0 105px;
      }

      &:nth-last-child(1) {
        margin: 0 105px 0 0;
      }

      &:only-child {
        margin: 0 105px 0 105px;
      }
    }
  }
}
////////////////////////////////////////////////////////////////////////////////////////////////////

// SUBSCRIPTION PRICE CONTAINER
.subscription-price-container {
  max-width: 200px;
  background: #FFFFFF;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 2px, rgba(0, 0, 0, 0.05) 0px 3px 6px;
  text-align: center;
  margin: 15px auto;

  p {
    font-family: "Robert Bold", sans-serif;
    font-size: 2rem;
    font-weight: 700;
    line-height: 2rem;
    padding: 0 12px;
    margin: 0;
  }

  span {
    display: inline-block;
    width: 100%;
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 300;
    margin: 0 0 12px 0;
    padding: 0 12px
  }

  h5 {
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 0.875rem;
    padding: 0 12px 15px 12px;
    margin: 0;
  }

  &.selected {
    p, span, h5 {
      color: $nano-primary-color;
    }
  }
}
////////////////////////////////////////////////////////////////////////////////////////////////////

// EMAIL ADDRESS STEP.
.agreeSmsDisclaimer {
  display: table !important;
  margin: $nano-space-sm auto 0 auto;
  text-align: left;
  max-width: 100% !important;

  input[type="checkbox"] { display: none; }

  // ICONS.
  i {
    display: none;
    margin: 0;
    font-size: $nano-font-size-xl;
    color: $nano-primary-color;

    @include vendor-prefix('transform', 'translateY(2.5px)');

    &.mdi-checkbox-blank-outline { display: inline-block; }

  }

  // DISCLAIMER MSG.
  span {
    display: inline-block;
    width: calc(100% - 20px);
    padding: 0 0 0 $nano-space-xxs;
    font-size: $nano-font-size-sm;

    @media (min-width: $nano-sm-breakpoint) { font-size: $nano-font-size-md; }

  }

  // ACTIVE INPUT.
  &.selected {
    .mdi-checkbox-marked { display: inline-block; }
    .mdi-checkbox-blank-outline { display: none; }
  }

  @media (min-width: $nano-sm-breakpoint) { max-width: 85% !important; }
  @media (min-width: $nano-lg-breakpoint) { max-width: 100% !important; }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

// CHECKOUT STEP - PHONE NUMBER FIELD.
.step[data-step='phone-number'] {
  margin: 0 0 $nano-space-sm 0;

  h3 {
    margin: 0 0 $nano-space-md 0;
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////

// SHIPPING ADDRESS STEP.
.shippingAddressStep {
  margin: 0 auto $nano-space-md auto;
  padding: 0;

  // @media (min-width: $nano-sm-breakpoint) { max-width: 380px; }
}

.phoneNumberFormGroup,
.shippingAddressFormGroup {
  label {
    position: relative;
    display: inline-block !important;
    width: 100% !important;
    max-width: 100% !important;
    margin: 0 0 $nano-space-sm 0;

    // INPUT LABEL.
    span {
      font-family: $nano-shipping-address-inputs-font-family;
      font-size: $nano-font-size-xs;
      font-weight: $nano-font-weight-light;
      text-transform: uppercase;
      text-align: left;
      letter-spacing: 0.5px;
      position: absolute;
      z-index: 99;
      top: 6.5px;
      padding: 0 0 0 $nano-space-xs;

      // REQUIRED '*'.
      b {
        color: $nano-required-color;
      }
    }

    input,
    select {
      @extend %nano-inputs;

      font-weight: $nano-font-weight-semibold;
      text-align: left;
      padding: $nano-space-sm $nano-space-xs $nano-space-xxs $nano-space-xs;

      // 'STATE' SELECT.
      &.stateInput {
        cursor: pointer;
        background-image: url('https://nano-home.github.io/nano/assets/brand/arrow-down-thin-circle-outline.svg');
        background-position: 95% center;
        background-repeat: no-repeat;
        background-size: 18px;
      }
    }
  }

  &.fourColLayout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 12px;
  }
}

.phoneNumberFormGroup label {
  margin: 0;
}

// 'ADDRESS LINE 1' INPUT & LABEL.
.addressLineOne {
  span {
    z-index: 99999 !important;
  }

  input {
    position: relative;
    z-index: 9999;
  }
}

// GOOGLE PLACES - SUGGESTIONS DROPDOWN.
.suggestionsGooglePlaces {
  width: 100%;
  margin: 0;
  padding: $nano-space-xs 0 0 0;
  position: absolute;
  z-index: 999;
  list-style-type: none;
  background: $nano-white-color;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid $nano-border-color;
  box-shadow: 0 6px 5px -2px #aeaeae;

  @include vendor-prefix('transform', 'translateY(-8px)');

  li {
    font-family: $nano-primary-font-family;
    font-size: $nano-font-size-sm;
    font-weight: $nano-font-weight-medium;
    color: $nano-secondary-color;
    text-align: left;
    padding: 10px $nano-space-xs;
    border-bottom: 1px solid $nano-border-color;
    opacity: 0.85;

    @include vendor-prefix('transition', 'all .125s ease');

    &:last-child {
      border-bottom: none;
    }

    @media (min-width: $nano-sm-breakpoint) {
      font-size: $nano-font-size-md;

      &:hover {
        cursor: pointer;
        opacity: 1;
        background: $nano-light-gray-color-1;
      }
    }
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////

.start .list {
  font-size: $nano-font-size-lg;
  display: none;
  margin: $nano-space-md auto 0;
  position: relative;
  background: $nano-background-color;
  overflow: auto;
  padding: $nano-space-sm 0 $nano-space-sm $nano-space-sm;
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 2px, rgb(0 0 0 / 5%) 0px 3px 6px;

  border: 1px solid $nano-border-color;

  @include vendor-prefix('border-radius', '10px');

  .button {
    font-size: $nano-font-size-sm;
    margin: $nano-space-xxs 0 0;
    padding: $nano-space-xxs $nano-space-sm;
  }

  &.active {
    display: block;
  }

  &.no-results {
    display: none !important;

    &:before {
      content: 'No results. Please try searching for another dimension.';
      opacity: 0.7;
      display: block;
      text-align: center;
    }
  }
}

.start ul:not(.suggestionsGooglePlaces) {
  font-size: 0;
  text-align: left;
  list-style: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;

  li {
    position: relative;
    background: $nano-white-color;
    border: 1px solid $nano-border-color;
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 2px, rgb(0 0 0 / 5%) 0px 3px 6px;
    padding: $nano-space-sm $nano-space-sm $nano-space-sm $nano-space-xxl;
    // display: none;
    margin: 0 $nano-space-sm 0 0;
    overflow: hidden;

    @include vendor-prefix('border-radius', '5px');

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 70px;
      height: 100%;
      object-fit: contain;
      object-position: center center;
      background-color: $nano-background-color;
    }

    // ITEM TITLE.
    .product-title {
      display: inline-block;

      strong {
        font-weight: $nano-font-weight-semibold;
      }
    }

    // ITEM PRICE.
    .variant-price {
      display: inline-block;
      font-weight: $nano-font-weight-medium;
      color: $nano-primary-color;
    }

    // CARBON ITEM.
    &[data-product*='Carbon'] {
      display: none !important;
    }

    &.visible {
      display: inline-block;
    }
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////

.start ul.radio {
  text-align: center;
  margin: 0 auto;
  padding: 0;

  // FILTERS QUANTITY BOX.
  li {
    display: inline-block;
    width: 45px;
    min-width: 0;
    height: 45px;
    cursor: pointer;
    position: relative;
    background: $nano-white-color;
    border: 1px solid $nano-border-color;
    box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
    margin: $nano-space-sm $nano-space-xxs 0;
    padding: 0;
    outline: 2px solid transparent;
    font-size: $nano-font-size-md;
    text-align: center;
    line-height: 42.5px;

    @include vendor-prefix('border-radius', '5px');
    @include vendor-prefix('transition', 'all .35s ease');

    &.selected {
      font-weight: $nano-font-weight-bold;
      color: $nano-white-color;
      background: $nano-primary-color;
      border-color: darken($nano-primary-color, 10);
    }

    &:focus-visible {
      outline: 2px solid $nano-outline-color;
    }

    @media (min-width: $nano-sm-breakpoint) {
      width: auto;
      min-width: 45px;
      float: none;
      margin: $nano-space-sm $nano-space-xs 0;
    }

    @media (min-width: $nano-lg-breakpoint) {
      &:hover {
        color: $nano-white-color;
        background: $nano-primary-color;
        border-color: darken($nano-primary-color, 10);
      }
    }
  }

  // RADIO BUTTONS & CHECKBOXES.
  $icons: ('radio-text', '\F043D', '\F043E'), ('checkboxes', '\F0131', '\F0132');

  @each $class, $unchecked, $checked in $icons {
    &.#{$class} {
      li {
        width: 100%;
        min-width: 100%;
        height: $nano-steps-checboxes-radio-height-mobile;
        display: block;
        margin: $nano-space-sm auto 0;
        padding: 0 $nano-space-md;
        text-align: left;
        line-height: 45px;

        &:before {
          content: '#{$unchecked}';
          margin: 0 $nano-space-sm 0 0;
        }

        // SELECTED OPTION.
        &.selected {
          &:before {
            content: '#{$checked}';
          }
        }

        @media (min-width: $nano-sm-breakpoint) {
          height: $nano-steps-checboxes-radio-height-desktop;
        }
      }

      @media (min-width: $nano-sm-breakpoint) {
        max-width: 305px;
      }
    }
  }

  @media (max-width: $nano-sm-breakpoint) {
    display: inline-block;
    width: 100%;
    max-width: 305px;
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////

.start .filter.search {
  input {
    width: 80px;
    padding: $nano-space-sm;
  }

  span.mdi-close {
    display: inline-block;
    width: 20px;
  }
}

.start .input-row {
  font-size: 0;
  text-align: left;
  display: none;
  width: 100%;
  max-width: 600px;
  position: relative;
  margin: 0 auto;
  padding: 12px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  // ROW FILTER NUMBER.
  &:before {
    font-size: $nano-font-size-sm;
    font-weight: $nano-font-weight-semibold;
    text-align: center;
    line-height: $nano-dimensions-filter-row-number-height;
    color: $nano-secondary-color;
    content: attr(data-filter);
    position: absolute;
    top: 15px;
    left: 0;
    width: $nano-dimensions-filter-row-number-width-desktop;
    opacity: 0.5;

    @media (min-width: $nano-md-breakpoint) {
      font-size: $nano-font-size-lg;
    }

    @media (max-width: $nano-sm-breakpoint) {
      width: $nano-dimensions-filter-row-number-width-mobile;
      height: 35px;
      line-height: 35px;
    }

    @media (max-width: $nano-md-breakpoint) {
      opacity: 1;
      z-index: 1;
      top: 12.5px;
      border-right: 1px solid $nano-border-color;
    }
  }

  @media (min-width: $nano-md-breakpoint) {
    padding: 12.5px 0 15px 40px;
  }
}

.filters-container {
  @media (max-width: $nano-sm-breakpoint) {
    max-width: 305px;
    margin: 0 auto;
  }
}

.start .input-row {
  input.search-box[disabled] {
    background: $nano-btn-disabled-background-color;
  }

  .input {
    width: auto;
    margin: 0 auto;
  }

  .numeral {
    display: inline-block;
    width: 30px;
  }

  .input.dimensions {
    max-width: 100%;
  }

  // DIMENSIONS - FILTER NAME INPUT.
  .input.label {
    display: inline-block;
    width: $nano-dimensions-filter-name-input-width-mobile;
    max-width: 100%;
    margin: 0 0 $nano-space-sm 0;

    input {
      height: $nano-dimensions-filter-name-height-mobile;
      text-align: left;
      padding: $nano-space-xs $nano-space-sm;
      box-shadow: 0 1px 1px rgb(0 0 0 / 10%);

      @media (max-width: $nano-md-breakpoint) {
        padding: $nano-space-xs $nano-space-sm $nano-space-xs 45px;
      }
      @media (min-width: $nano-sm-breakpoint) {
        height: $nano-dimensions-filter-name-height-desktop;
      }
    }

    @media (min-width: $nano-md-breakpoint) {
      width: $nano-dimensions-filter-name-input-width-desktop;
      transform: translateY(1.5px);
      margin: 0 20px 0 0;
    }
  }

  // DIMENSIONS - SQUARE INPUTS.
  .dimensions {
    input {
      width: $nano-dimensions-square-input-width-mobile;
      height: $nano-dimensions-square-input-height-mobile;
      padding: $nano-space-xxs;
      box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
      font-size: $nano-font-size-md;

      @media (min-width: $nano-md-breakpoint) {
        width: $nano-dimensions-square-input-width-desktop;
        height: $nano-dimensions-square-input-height-desktop;
      }
    }

    // 'X' INPUTS SEPARATOR.
    .mdi-close {
      display: inline-block;
      width: $nano-dimensions-square-x-separator-width;
      font-size: $nano-font-size-md;
      text-align: center;
      opacity: 0.6;
    }

    .mdi-quantity {
      display: inline-block;
      width: 40px;
      font-size: $nano-font-size-md;
      text-align: center;
      opacity: 0.6;
    }

    .mdi-plus {
      display: inline-block;
      width: 40px;
      font-size: 1rem;
      text-align: center;
      opacity: 0.6;
    }
  }
}

.start .input-row.active-selection .list {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  height: 42px;
  padding: 0;
  width: calc(100% - 360px);
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 2px, rgb(0 0 0 / 5%) 0px 4px 12px;
  border: 1px solid $nano-border-color;
  overflow: hidden;

  @include vendor-prefix('border-radius', '5px');

  ul li {
    display: none !important;

    img {
      width: 50px;
    }

    &.selected {
      display: block !important;
      margin: 0;
      width: 100%;
      padding: $nano-space-xs $nano-space-sm $nano-space-xs 65px;
      border: 0 none;
    }
  }

  .unselect-button.button {
    margin: 0;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    position: absolute;
    padding: 0;
    width: 22px;
    height: 22px;
    background: rgba(103, 110, 144, 0.3);
    border: 0 none;
    min-width: initial;
    margin-top: -1px;
    color: $nano-white-color;
    line-height: 20px;

    @include vendor-prefix('border-radius', '100%');
  }
}

.start .input-row.active-selection .dimensions {
  display: none;
}

////////////////////////////////////////////////////////////////////////////////////////////////////

.start .guide {
  position: relative;
  width: $nano-dimensions-filter-img-width-mobile;
  // max-width: 250px;
  margin: 0 auto $nano-space-lg auto;

  // FILTER IMG.
  img {
    width: 100%;
    object-fit: contain;
  }

  .dim {
    position: absolute;
    text-align: center;
    margin: 0 auto;

    span {
      display: inline-block;
      background: $nano-light-color;
      padding: $nano-space-xxs;
      font-weight: $nano-font-weight-medium;
      color: rgba(0, 0, 0, 0.3);
    }

    // WIDTH.
    &.w {
      bottom: -30px;
      left: 45%;
      transform: rotate(19deg) translateX(-50%);
      width: 100px;

      &:before {
        content: '';
        z-index: -1;
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.1);
        height: 1px;
      }

      @media (min-width: $nano-md-breakpoint) {
        bottom: -20px;
        left: 50%;
      }
    }

    // LENGTH.
    &.l {
      position: absolute;
      top: 50%;
      right: 15px;
      height: 0;
      transform: translateY(-50%);
      padding: 55px 0;

      &:before {
        content: '';
        z-index: -1;
        position: absolute;
        top: 0;
        left: 50%;
        width: 1px;
        background: rgba(0, 0, 0, 0.1);
        height: 100%;
      }

      @media (min-width: $nano-md-breakpoint) {
        right: 35px;
        padding: 65px 0;
      }
    }

    &.d {
      display: none;
    }
  }

  @media (min-width: $nano-md-breakpoint) {
    width: $nano-dimensions-filter-img-width-desktop;
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////

.start .selections ul {
  &:not(.profile):not(.filter-list) {
    width: 100%;
    overflow: hidden;
    max-width: 400px;
    margin: 0 auto;
    background: $nano-white-color;
    box-shadow: 0 1px 2px rgb(0 0 0 / 20%);

    @include vendor-prefix('border-radius', '5px');

    li {
      position: relative;
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      padding: $nano-space-sm 120px $nano-space-sm $nano-space-md;
      overflow: hidden;
      font-size: $nano-font-size-lg;
      line-height: 28px;
      white-space: nowrap;
      text-overflow: ellipsis;

      span.dimensions {
        position: absolute;
        right: 20px;
        font-weight: $nano-font-weight-semibold;
      }
    }
  }

  &.profile,
  &.filter-list {
    li {
      font-size: $nano-font-size-base;
      margin: 0;
      padding: 15px $nano-space-md;
      box-shadow: none;
      border: 1px solid $nano-border-color;
      border-top: none;

      @include vendor-prefix('border-radius', '0');

      span {
        max-width: 50%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.label {
          float: left;
        }

        &.dimensions {
          font-weight: $nano-font-weight-bold;
          float: right;
        }
      }

      &:first-child {
        border-top: 1px solid $nano-border-color;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      &:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }

    @media (min-width: $nano-md-breakpoint) {
      max-width: 700px;
    }
  }

  &.filter-list {
    margin-top: 20px;
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////

/// Reusable Selectable Container Small
.selectable-small-container {
  cursor: pointer;
  position: relative;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 2px, rgba(0, 0, 0, 0.05) 0px 3px 6px;
  transition: all .35s ease;
  text-align: center;

  .selector-element {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &.selected {
    .title-container:before {
      content: "";
      opacity: 0.5;
      background-color: $nano-primary-color;
      //background: url('https://nano-home.github.io/nano/assets/brand/Nano_Pattern_Active.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    h4 {
      color: #FFFFFF;
    }

    span {
      color: $nano-primary-color;
    }
  }

  &:hover {
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 2px, rgb(0 0 0 / 10%) 0px 3px 6px;
    @include vendor-prefix('transform', 'scale(1.05)');
  }

  .title-container {
    position: relative;
    margin: 0 0 20px 0;
    padding: 20px 12px;
    border-bottom: 1px solid #DDDDDD;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0.35;
      //background: url(https://nano-home.github.io/nano/assets/brand/Nano_Pattern_Inactive.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      transition: all .25s ease;
    }

    h4 {
      position: relative;
      margin: 0;
    }
  }

  .data-container {
    position: relative;
    padding: 0 20px 20px 20px;
    display: inline-flex;
    flex-wrap: wrap;
    align-content: center;
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////

/// Multi Selection step
.multi-select {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    row-gap: 15px;

    .selected .title-container:before {
      opacity: 0.8;
    }

    .data-container {
      min-height: 100px;

      .filler {
        position: relative;
        top: 0;
        right: 0;
        width: 50px;
        height: 50px;
        background-color: #000000;
        opacity: .35;
      }
      
      span {
        font-weight: bold;
      }
    }

    .selected .data-container .filler {
      background-color: $nano-primary-color;
      opacity: 0.7;
    }
  }

  .mask{
    display: inline-block;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat; 
    -webkit-mask-size: contain;
    mask-size: contain;

    &.paw {
      -webkit-mask-image: url(../assets/paw.png);
      mask-image: url(../assets/paw.png);
    }

    &.odors {
      -webkit-mask-image: url(../assets/scent-off.png);
      mask-image: url(../assets/scent-off.png);
    }

    &.allergies {
      -webkit-mask-image: url(../assets/sneeze.png);
      mask-image: url(../assets/sneeze.png);
    }
  }
}
////////////////////////////////////////////////////////////////////////////////////////////////////

// SUBSCRIPTION SELECTION MODAL
.modal-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .modal-content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    position: fixed;
    width: 80%;
    height: 80%;
    background-color: #ebebeb;
    overflow: auto;
    scroll-snap-type: y mandatory;
    text-align: center;

    .modal-buttons {
      padding-top: 80px
    }

    .modal-element {
      margin: 20vh 0;
      scroll-snap-align: center;
    }
    
    .recommended-vs-current {
      z-index: 1;
      background: #EBEBEB;
      position: fixed;
      width: inherit;
      display: grid;
      column-gap: 15px;
      grid-template-columns: max-content max-content;
      align-content: center;
      justify-content: center;
      text-align: center;
      padding: 20px 0;
      border-bottom: 1px solid #DDDDDD;

      .compare-item {
        border: 1px solid #DDDDDD;
        border-radius: 6px;
        padding-top: 12px;
      }

      .recommended {
        p, span, h5 {
          color: $nano-primary-color;
        }
      }
    }

    .merv-selection {
      padding-top: 120px;

      .merv-options-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        column-gap: 10px;
      }

      .selectable-small-container {
        span {
          font-weight: 600;
        }

        .title-container {
          h4 {
            color: #000000;
          }

          &:before {
            background: url(https://nano-home.github.io/nano/assets/brand/Nano_Pattern_Inactive.jpg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            opacity: 0.3;
          }
        }

        &.selected .title-container:before {
          background: url(https://nano-home.github.io/nano/assets/brand/Nano_Pattern_Active.jpg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          opacity: 0.3;
        }
      }
    }

    .odor-elimination-layer {
      .tier-selection {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        column-gap: 10px;

        div {
          cursor: pointer;
          border: 1px solid black;
          border-radius: 6px;
          padding: 5px 8px;

          &.selected {
            border: 1px solid $nano-primary-color;
            background: rgba(245, 166, 79, 0.3);
          }
        }
      }
    }

    .shipping-schedule {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;

      .frequency-selector {
        margin: 30px;

        .options-bar {
          background: #DDDDDD;
          border-radius: 4px;
          width: 350px;
          height: 10px;
          display: flex;
          flex-wrap: wrap;
          align-content: center;
          justify-content: space-between;

          .ship-schedule-option {
            cursor: pointer;
            width: 15px;
            height: 15px;
            border-radius: 6px;
            background: #A5998D;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .wording {
              position: relative;
              top: 15px;
              width: min-content;
            }

            .recommended {
              position: relative;
              top: 10px;
              color: $nano-primary-color;

              &::before {
                content: "\25B2";
              }
            }

            &.selected {
              background: $nano-primary-color;
            }
          }
        }
      }
    }

    @media (max-width: 550px) {
      width: 100%
    }
  }
}
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// FILTER DESCRIPTION.
.filter-description {
  margin: 0 auto 20px auto;
  color: $nano-primary-color;

  div > b::before {
    content: "\2022";
  }
}
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// FREQUENCY SELECTOR.
.frequencySelector {
  margin: $nano-space-md auto 0 auto;

  @media (min-width: $nano-sm-breakpoint) {
    margin: $nano-space-lg auto 0 auto;
  }
}

.frequencyPlansContainer {
  display: grid;
  width: 100%;
  row-gap: 10px;
  column-gap: 7.5px;
  grid-template-columns: repeat(3, 1fr);

  @media (min-width: $nano-sm-breakpoint) {
    row-gap: 0;
    column-gap: 15px;
  }
}

// PLAN BOX.
.frequencyPlan {
  cursor: pointer;
  margin: 0;
  padding: 0;
  background: $nano-white-color;
  border: 1px solid $nano-border-color;
  position: relative;
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 2px, rgb(0 0 0 / 5%) 0px 3px 6px;

  @include vendor-prefix('transition', 'all .35s ease');

  &:nth-child(1) {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &:nth-child(3) {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  @media (min-width: $nano-sm-breakpoint) {
    &:hover {
      box-shadow: rgb(0 0 0 / 16%) 0px 1px 2px, rgb(0 0 0 / 10%) 0px 3px 6px;

      @include vendor-prefix('transform', 'scale(1.05)');
    }
  }
}

// PLAN BOX.
.frequencyPlan {
  // CLICKABLE ELEMENT.
  .frequencySelectorElement {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

// 'BEST DEAL' RIBBON.
.frequencyRibbon {
  font-family: $nano-best-deal-ribbon-font-family;
  font-size: 0.575rem;
  font-weight: $nano-font-weight-bold;
  color: $nano-best-deal-ribbon-font-color;
  text-align: center;
  line-height: 15px;

  position: absolute;
  width: calc(100% + 10px);
  z-index: 10;
  margin: 0;
  padding: 0 $nano-space-xs;
  zoom: 1;
  top: 4.5px;
  left: -5px;
  height: 15px;
  background: $nano-best-deal-ribbon-background-color;

  -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px rgb(0 0 0 / 20%);

  &:after {
    border-color: transparent $nano-best-deal-ribbon-border-color;
    content: '';
    position: absolute;
    z-index: -20;
    top: 100%;
    left: auto;
    border-style: solid;
    border-width: 0 0 5px 5px;
    right: 0;
  }

  @media (min-width: $nano-md-breakpoint) {
    width: auto;
    top: -7.5px;
    right: -5px;
    left: unset;
    height: 22.5px;
    font-size: $nano-font-size-xs;
    line-height: 22.5px;
  }

  @media (max-width: $nano-md-breakpoint) {
    &:before {
      border-color: transparent $nano-best-deal-ribbon-border-color;
      content: '';
      position: absolute;
      z-index: -20;
      top: 100%;
      right: auto;
      border-style: solid;
      border-width: 0 5px 5px 0;
      left: 0;
    }
  }
}

.frequencyDataContainer {
  text-align: center;
  padding: 0 0 22.5px 0;

  @media (min-width: $nano-sm-breakpoint) {
    padding: 0 0 $nano-space-md 0;
  }
}

.frequencyDataContainer {
  // FREQUENCY.
  .frequencyTitleContainer {
    position: relative;
    margin: 0 0 $nano-space-md 0;
    padding: 22.5px $nano-space-xxs;
    border-bottom: 1px solid $nano-border-color;

    h4 {
      position: relative;
      margin: 0;
      font-size: $nano-font-size-base;

      // ICON.
      i {
        font-style: normal;
        margin: 0 0 0 $nano-space-xxs;
      }

      @media (min-width: $nano-sm-breakpoint) {
        font-size: $nano-font-size-lg;
      }
    }

    // BACKGROUND IMAGE.
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0.35;
      background: url('https://nano-home.github.io/nano/assets/brand/Nano_Pattern_Inactive.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      @include vendor-prefix('transition', 'all .25s ease');
    }

    @media (min-width: $nano-sm-breakpoint) {
      padding: $nano-space-md $nano-space-sm;
    }
  }

  // PRICE.
  p {
    margin: 0;
    padding: 0 $nano-space-xxs;
    font-family: $nano-heading-font-family;
    font-size: 1.5rem;
    font-weight: $nano-font-weight-bold;
    line-height: 1.5rem;

    @media (min-width: $nano-sm-breakpoint) {
      font-size: 2rem;
      line-height: 2rem;
      padding: 0 $nano-space-sm;
    }
  }

  // 'PER MONTH' CAPTION.
  span {
    display: inline-block;
    width: 100%;
    margin: 0 0 $nano-space-xs 0;
    padding: 0 $nano-space-xxs;
    font-size: $nano-font-size-sm;
    font-style: italic;
    font-weight: $nano-font-weight-light;

    @media (min-width: $nano-sm-breakpoint) {
      font-size: $nano-font-size-md;
      margin: 0 0 $nano-space-sm 0;
      padding: 0 $nano-space-sm;
    }
  }

  // PLAN INFO.
  h5 {
    opacity: 1;
    margin: 0;
    padding: 0 $nano-space-xs;
    font-size: $nano-font-size-xs;
    font-weight: $nano-font-weight-medium;
    line-height: 0.75rem;

    @media (min-width: $nano-sm-breakpoint) {
      font-size: $nano-font-size-sm;
      line-height: 0.875rem;
      padding: 0 $nano-space-sm;
    }
  }
}

// ACTIVE PLAN.
.frequencyDataContainer {
  &.active,
  &.selected {
    p,
    h4,
    h5,
    span {
      color: $nano-primary-color;
    }

    .frequencyTitleContainer:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0.15;
      background: url('https://nano-home.github.io/nano/assets/brand/Nano_Pattern_Active.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////

// PLANS COPY.
.frequencyCopyContainer {
  display: table;
  width: 100%;
  margin: $nano-space-md auto 0 auto;

  @media (min-width: $nano-md-breakpoint) {
    max-width: 525px;
  }
}

.frequencyCopy {
  margin: 0;
  cursor: pointer;
  text-align: left;
  display: inline-flex;

  // CHECKBOX.
  span {
    margin: 0 $nano-space-xxs 0 0;
    float: left;
    font-size: $nano-font-size-xxl;
    color: $nano-primary-color;

    &.mdi-checkbox-marked {
      display: none;
    }

    @media (max-width: $nano-md-breakpoint) {
      &.first-line-icon {
        @include vendor-prefix('transform', 'translateY(-3.5px)');
      }
      &.second-line-icon {
        @include vendor-prefix('transform', 'translateY(-7.5px)');
      }
    }

    @media (min-width: $nano-md-breakpoint) {
      margin: 0 $nano-space-xs 0 0;

      @include vendor-prefix('transform', 'translateY(-5.5px)');
    }
  }

  // COPY.
  p {
    display: table;
    margin: 0;

    b {
      font-family: $nano-heading-font-family;
    }

    @media (max-width: $nano-md-breakpoint) {
      font-size: $nano-font-size-sm;
    }
  }

  // ACTIVE OPTION.
  &.selected {
    .mdi-checkbox-marked {
      display: inline-block;
    }
    .mdi-checkbox-blank-outline {
      display: none;
    }
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////

// SHIPPING SCHEDULE.
.frequencyShippingSchedule {
  margin: 0 auto $nano-space-xs auto;

  h3 {
    margin: 0 auto $nano-space-sm auto;

    // ICON.
    i {
      font-style: normal;
      margin: 0 0 0 $nano-space-xxs;
    }
  }

  .scheduleRow {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    column-gap: 10px;
    background: $nano-white-color;
    padding: $nano-space-sm;

    border-right: 1px solid $nano-border-color;
    border-bottom: 1px solid $nano-border-color;
    border-left: 1px solid $nano-border-color;

    span {
      font-size: $nano-font-size-md;

      &.alignLeft {
        font-style: italic;
        text-align: left;
      }

      &.alignRight {
        font-family: $nano-heading-font-family;
        text-align: right;
      }
    }

    &:nth-of-type(1) {
      border-top: 1px solid $nano-border-color;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    @media (min-width: $nano-sm-breakpoint) {
      padding: $nano-space-sm $nano-space-md;
    }
  }

  @media (min-width: $nano-sm-breakpoint) {
    margin: 0 auto $nano-space-md auto;
  }
  @media (min-width: $nano-md-breakpoint) {
    max-width: 350px;
    min-width: 200px;
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////

// STOCK LIST FILTERS SELECTED.
.start .stock ul {
  width: 100%;
  overflow: hidden;
  max-width: 350px;
  margin: 0 auto;
  background: $nano-white-color;
  box-shadow: 0 1px 2px rgb(0 0 0 / 20%);

  @include vendor-prefix('border-radius', '5px');

  li {
    width: 100%;
    position: relative;
    font-size: $nano-font-size-base;
    line-height: 28px;
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    padding: $nano-space-sm $nano-space-md $nano-space-sm 87px;

    // FILTER IMG.
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 57px;
      height: auto;
      object-fit: contain;
      padding: $nano-space-xxs;

      @media (min-width: $nano-md-breakpoint) {
        width: 77px;
        object-position: center center;
      }
    }

    @media (min-width: $nano-md-breakpoint) {
      font-size: $nano-font-size-lg;
    }
  }

  @media (min-width: $nano-md-breakpoint) {
    max-width: 500px;
  }
}

.start .stock ul li {
  // TOP INFO ROW.
  .line-one {
    font-size: $nano-font-size-md;
    font-weight: $nano-font-weight-semibold;
    position: relative;

    span {
      &.size {
        &:before {
          content: ' - ';

          // @media (max-width: $nano-md-breakpoint) { display: none; }
        }

        // @media (max-width: $nano-md-breakpoint) { display: block; }
      }

      &.price {
        position: absolute;
        top: 0;
        right: 0;
        font-weight: $nano-font-weight-semibold;

        &:before {
          content: '$';
        }
      }
    }

    @media (max-width: $nano-md-breakpoint) {
      line-height: 1rem;
    }
    @media (min-width: $nano-md-breakpoint) {
      font-size: $nano-font-size-base;
    }
  }

  // BOTTOM INFO ROW.
  .line-two {
    font-size: $nano-font-size-md;
    font-weight: $nano-font-weight-regular;
    opacity: 0.7;

    @media (max-width: $nano-md-breakpoint) {
      line-height: 1rem;
    }
  }

  // CARBON SWAP BUTTON.
  .swap {
    display: inline-block;
    position: relative;
    bottom: auto;
    right: auto;
    margin: 0;
    padding: $nano-space-xxs $nano-space-xs;
    background: $nano-swap-item-color;
    cursor: pointer;
    font-size: $nano-font-size-xs;
    font-weight: $nano-font-weight-semibold;
    text-transform: uppercase;
    letter-spacing: 0.25px;
    line-height: 1;
    color: $nano-white-color;

    @include vendor-prefix('border-radius', '5px');
    @include vendor-prefix('transition', 'all .25s ease');

    &:hover {
      background: darken($nano-swap-item-color, 5);
    }

    @media (min-width: $nano-md-breakpoint) {
      position: absolute;
      right: 15px;
      bottom: 50%;

      @include vendor-prefix('transform', 'translateY(50%)');
    }

    display: none;
  }

  // TOTAL STOCK.
  &.total {
    font-weight: $nano-font-weight-medium;
    padding: $nano-space-sm $nano-space-md;

    span {
      position: absolute;
      right: 20px;
      font-weight: $nano-font-weight-semibold;

      &:before {
        content: '$';
      }
    }
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////

// SHIPPING ADDRESS TITLE.
.step[data-step='shipping-address'] h3 {
  margin: 0 auto 15px auto;
}

////////////////////////////////////////////////////////////////////////////////////////////////////

.input-row[data-filter='1'],
.input-row[data-filter='2'],
.input-row[data-filter='3'],
.input-row[data-filter='4'],
.input-row[data-filter='5'],
.input-row[data-filter='6'] {
  display: block;
}

////////////////////////////////////////////////////////////////////////////////////////////////////

.start .step {
  // SUBTITLES.
  &[data-step='old'],
  &[data-step='merv-1'],
  &[data-step='filters'],
  &[data-step='confirm'] {
    h5 {
      @media (min-width: $nano-md-breakpoint) {
        max-width: 515px;
      }
    }
  }

  &[data-step='concerns'] {
    h5 {
      @media (min-width: $nano-md-breakpoint) {
        max-width: 485px;
      }
    }
  }

  &[data-step='waitlist'] h3 span.mdi {
    display: block;
    opacity: 0.3;
    margin: 0 0 $nano-space-sm;
    font-size: 2.5rem;
    line-height: 1;
  }

  &[data-step='filters'] .buttons-footer {
    @media (max-width: $nano-sm-breakpoint) {
      padding-bottom: 40px;
    }
  }

  // 'EMAIL' STEP SUBTITLE.
  &[data-step='email'] h5 {
    @media (max-width: $nano-sm-breakpoint) {
      max-width: 260px;
    }
  }

  // 'HOME KIND' STEP TITLE.
  &[data-step='home-type'] h3 {
    @media (max-width: $nano-sm-breakpoint) {
      max-width: 230px;
    }
  }

  // 'SQUARE FOOTAGE' STEP SUBTITLE.
  &[data-step='square-footage'] h5 {
    @media (max-width: $nano-sm-breakpoint) {
      max-width: 295px;
    }
  }

  // 'MERV 1' STEP SUBTITLE.
  &[data-step='merv-1'] h5 {
    @media (max-width: $nano-sm-breakpoint) {
      max-width: 285px;
    }
  }

  // 'AC SYSTEM' STEP TITLE & SUBTITLE.
  &[data-step='old'] {
    h3 {
      @media (max-width: $nano-sm-breakpoint) {
        max-width: 250px;
      }
    }

    h5 {
      @media (max-width: $nano-sm-breakpoint) {
        max-width: 285px;
      }
    }
  }

  // 'FILTERS COUNT' STEP TITLE.
  &[data-step='count'] h3 {
    @media (max-width: $nano-sm-breakpoint) {
      max-width: 235px;
    }
  }

  // 'FILTERS SELECTION' STEP SUBTITLE.
  &[data-step='filters'] h5 {
    @media (max-width: $nano-sm-breakpoint) {
      max-width: 285px;
    }
  }

  // 'PROFILE SET' STEP.
  &[data-step='profile-set'] {
    h5 {
      @media (max-width: $nano-sm-breakpoint) {
        max-width: 225px;
        margin: 0 auto $nano-space-md auto;
      }
    }

    @media (min-width: $nano-md-breakpoint) {
      max-width: 500px;
      margin: 0 auto;
    }
  }

  // CONFIRMATION STEP SUBTITLE.
  &[data-step='confirm'] {
    h5 {
      margin: 0 auto 8px auto;

      @media (max-width: $nano-sm-breakpoint) {
        max-width: 290px;
      }
    }

    h3 {
      margin: 0 auto 20px auto;
    }
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////

// OLD STYLES - STILL NEED TO REVIEW & REMOVED!
@media screen and (max-width: 1023px) {
  .start label {
    display: inline-block;
    max-width: 260px;
  }
}

@media screen and (max-width: 700px) {
  .start .list {
    max-width: calc(100vw - 80px);
  }

  .start .input-row.active-selection {
    padding-bottom: 45px;
  }

  .start .input-row.active-selection .input.label input {
    @include vendor-prefix('border-radius', '6px 6px 0 0');
  }

  .start .input-row.active-selection .list {
    width: 100%;
    top: 41px;
    max-width: none;

    @include vendor-prefix('border-radius', '0 0 6px 6px');
  }

  .start .input-row.active-selection .list ul li.selected {
    background: $nano-background-color;
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////
