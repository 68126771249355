////////////////////////////////////////////////////////////////////////////////////////////////////

@import "breakpoints";
@import "colors";
@import "spacing";
@import "typography";

////////////////////////////////////////////////////////////////////////////////////////////////////

@mixin wizard-titles ($font-size-desktop, $font-size-mobile, $font-weight, $line-height-desktop, $line-height-mobile, $margin-desktop, $margin-mobile, $opacity, $max-width-desktop, $max-width-mobile) {
  font-size: $font-size-mobile;
  font-weight: $font-weight;
  line-height: $line-height-mobile;
  text-align: center;
  margin: $margin-mobile;

  opacity: $opacity;
  padding: 0;
  max-width: $max-width-mobile;

  @media (min-width: $nano-md-breakpoint) {
    font-size: $font-size-desktop;
    line-height: $line-height-desktop;
    max-width: $max-width-desktop;
    margin: $margin-desktop;
  }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

// VENDOR PREFIXES.
@mixin vendor-prefix ($name, $value) {
  @each $vendor in ('-webkit-', '-moz-', '-ms-', '-o-', '') {
    #{$vendor}#{$name}: #{$value};
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////
