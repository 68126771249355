////////////////////////////////////////////////////////////////////////////////////////////////////

@import "breakpoints";
@import "colors";
@import "mixins";
@import "spacing";
@import "typography";

////////////////////////////////////////////////////////////////////////////////////////////////////

.nano-tooltip {
  position: relative;
  display: inline-block;

  // INFO. ICON.
  i {
    font-size: $nano-font-size-base;
    font-weight: $nano-font-weight-bold;
    font-style: normal;
    margin: 0 0 0 $nano-space-xxs;

    &:before {
      content: '\F02FD';
      font-family: $nano-mdi-icons-font-family;
      font-weight: $nano-font-weight-semibold;
    }

  }

  $tooltip: ("blueAccent", $nano-tooltip-blue-background-color), ("redAccent", $nano-tooltip-red-background-color);

  @each $tooltipClass, $tooltipColor in $tooltip {

    // TOOLTIP TEXT.
    .nano-tooltip-text.#{$tooltipClass} {
      visibility: hidden;
      opacity: 0;
      width: 185px;
      padding: $nano-space-sm;
      background: #{$tooltipColor};
      color: $nano-tooltip-font-color;
      text-align: center;

      position: absolute;
      z-index: 1;

      @include vendor-prefix ('border-radius', '5px');
      @include vendor-prefix ('transition', 'all .5s ease');

      // TOP TOOLTIP.
      &.topTooltip {
        width: 180px;
        bottom: 100%;
        left: calc(50% + 2px);
        margin-left: -90px; /* Use half of the width (120/2 = 60), to center the tooltip */

        // TOP ARROW.
        &:after {
          content: " ";
          position: absolute;
          bottom: -10px;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: #{$tooltipColor} transparent transparent transparent;
        }

      }

      // RIGHT TOOLTIP.
      // &.rightTooltip {
        // top: -5px;
        // left: 25px;
      // }

      // BOTTOM TOOLTIP.
      // &.bottomTooltip {
        // width: 265px;
        // top: calc(100% + 5px);
        // left: 50%;
        // margin-left: -132.5px; /* Use half of the width (120/2 = 60), to center the tooltip */
      // }

      // LEFT TOOLTIP.
      &.leftTooltip {
        right: 110%;
        bottom: 50%;
        transform: translateY(50%);

        // LEFT ARROW.
        &:after {
          content: " ";
          position: absolute;
          bottom: calc(50% - 5px);
          right: -10px;
          left: unset;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: transparent transparent transparent #{$tooltipColor};
        }

      }

      // TOOLTIP COPY.
      p {
        font-size: $nano-font-size-sm;
        margin: 0;
        padding: 0;

        &:first-letter { text-transform: capitalize; }

      }

    }

  }

  // SHOW TOOLTIP CONTENT ON HOVER.
  @media (min-width: $nano-lg-breakpoint) {
    &:hover {
      .nano-tooltip-text {
        visibility: visible;
        opacity: 1;
      }
    }
  }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

// TOOLTIP HTML MARKUP.
// <div class="nano-tooltip">
  // <i></i>
  // <span class="nano-tooltip-text">
    // <p></p>
  // </span>
// </div>

////////////////////////////////////////////////////////////////////////////////////////////////////
