////////////////////////////////////////////////////////////////////////////////////////////////////

$nano-primary-color: #f5a64f;
$nano-secondary-color: #3c4858;

////////////////////////////////////////////////////////////////////////////////////////////////////

$nano-body-background-color: #f8fbfd;
$nano-header-background-color: #ffffff;
$nano-header-box-shadox-color: #dedee4;
$nano-header-font-color: #24254e;

////////////////////////////////////////////////////////////////////////////////////////////////////

$nano-selection-font-color: #ffffff;
$nano-selection-color: #f5a64f;

////////////////////////////////////////////////////////////////////////////////////////////////////

$nano-white-color: #ffffff;
$nano-border-color: #dddddd;
$nano-button-color: #222222;
$nano-outline-color: #222222;
$nano-placeholder-color: #aaaaaa;
$nano-light-gray-color-1: #f2f2f2;
$nano-required-color: #ff0000;
$nano-error-color: #df1b41;

////////////////////////////////////////////////////////////////////////////////////////////////////

$nano-btn-disabled-font-color: #6e6e6e;
$nano-btn-disabled-border-color: #dddddd;
$nano-btn-disabled-background-color: #f2f2f2;

////////////////////////////////////////////////////////////////////////////////////////////////////

$nano-swap-item-color: #009688;
$nano-best-deal-ribbon-background-color: #ff9600;
$nano-best-deal-ribbon-border-color: #dc5f0b;
$nano-best-deal-ribbon-font-color: #ffffff;

////////////////////////////////////////////////////////////////////////////////////////////////////

$nano-light-color: #f9fafb;
$nano-background-color: #fafafa;

////////////////////////////////////////////////////////////////////////////////////////////////////

$nano-tooltip-blue-background-color: #3c4858;
$nano-tooltip-red-background-color: #df1b41;
$nano-tooltip-font-color: #ffffff;

////////////////////////////////////////////////////////////////////////////////////////////////////

// HEADER.
$nano-dashboard-header-background-color: linear-gradient(to right, #dfe6e9, #f5f5f7);
// $nano-dashboard-header-background-color:                linear-gradient(to right, #111827, #243B55);

// NAVIGATION.
$nano-dashboard-nav-link-color: #2d3436;
$nano-dashboard-nav-link-active-color: $nano-dashboard-nav-link-color;

// BORDER.
$nano-dashboard-border-color: #f3f3ff;

// UPGRADE.
$nano-dashboard-upgrade-account-font-color: #ff9800;
$nano-dashboard-upgrade-account-background-color: #fff8ef;
$nano-dashboard-content-block-upgrade-background-color: #fff8ef;
$nano-dashboard-content-block-upgrade-font-color: #f09c26;
$nano-dashboard-content-block-upgrade-button-background-color: #f29d04;
$nano-dashboard-content-block-upgrade-button-font-color: #ffffff;

// SUBSCRIPTION.
$nano-dashboard-content-block-subscription-background-color: #3abf7c;
$nano-dashboard-content-block-subscription-font-color: #ffffff;

// STATUS.
$nano-dashboard-content-block-status-background-color: #f7cd65;
$nano-dashboard-content-block-status-paid-background-color: #3abf7c;
$nano-dashboard-content-block-status-font-color: #ffffff;

$nano-dashboard-content-block-background-color: #ffffff;
$nano-dashboard-content-block-title-color: #32325d;
$nano-dashboard-content-block-button-background-color: #ffffff;
$nano-dashboard-content-block-button-background-color-hover: #fafafa;
$nano-dashboard-content-block-cancel-button-color: #cd3d64;
$nano-dashboard-profile-row-background-color: #f9f9f9;
$nano-dashboard-profile-cancel-button-background-color: #ffffff;
$nano-dashboard-profile-cancel-button-font-color: #999999;
$nano-dashboard-profile-edit-input-border-color: #a0cd6085;
$nano-dashboard-profile-error-accent-color: #df1b41;
$nano-dashboard-profile-error-accent-color-light: #df1b4115;

// MODAL.
$nano-dashboard-profile-modal-overlay-background-color: rgba(31, 32, 41, 0.85);
$nano-dashboard-profile-modal-background-color: #4a72e8;
$nano-dashboard-profile-modal-close-btn-color: #ffffff;
$nano-dashboard-profile-modal-font-color: #ffffff;
$nano-dashboard-modal-button-background-color: #ffffff;
$nano-dashboard-modal-button-font-color: #4a72e8;

// BANNER.
$nano-dashboard-banner-background-color: #4a72e8;
$nano-dashboard-banner-border-color: darken(#4a72e8, 10);
$nano-dashboard-banner-font-color: #ffffff;
$nano-dashboard-banner-button-background-color: #ffffff;
$nano-dashboard-banner-button-font-color: darken(#4a72e8, 10);

// HOME.
$nano-dashboard-home-subscription-boxes-background-color: linear-gradient(25deg, #eaeaea, #ffffff);
// $nano-dashboard-home-subscription-boxes-background-color: linear-gradient(25deg , #111827, #3C4858);
$nano-dashboard-home-subscription-boxes-font-color: #3c4858;
$nano-dashboard-home-eco-delivery-accent-color: #03a64a;
$nano-dashboard-home-invoices-box-background-color: #ffffff;
// $nano-dashboard-home-invoices-box-background-color:       linear-gradient(25deg , #8AC4E5, #A4DEFF);
$nano-dashboard-home-subscriptions-box-background-color: #ffffff;
$nano-dashboard-complete-profile-accent-color: #4a72e8;
$nano-dashboard-complete-profile-background-color: #ffffff;
$nano-dashboard-complete-profile-font-color: #4a72e8;
// $nano-dashboard-blue-accent-box-background-color:         linear-gradient(25deg , #2D3436, #636E72);
$nano-dashboard-blue-accent-box-background-color: linear-gradient(25deg, #111827, #3c4858);
// $nano-dashboard-orange-accent-box-background-color:        linear-gradient(25deg , #2D3436, #636E72);
$nano-dashboard-orange-accent-box-background-color: linear-gradient(25deg, #111827, #3c4858);
$nano-dashboard-gray-accent-box-background-color: linear-gradient(25deg, #262626, #4a4f59);
$nano-dashboard-cancelled-subscription-accent-color: #df1b41;
$nano-dashboard-cancelled-subscription-background-color: #ffffff;

////////////////////////////////////////////////////////////////////////////////////////////////////

$nano-progress-bar-container-background-color: #fbfbfb;
$nano-progress-bar-background-color: #f5a64f;

////////////////////////////////////////////////////////////////////////////////////////////////////

// CHECKOUT STEP.
$nano-checkout-step-success-color: #77c181;
$nano-checkout-step-error-color: #df1b41;

////////////////////////////////////////////////////////////////////////////////////////////////////
