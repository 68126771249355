////////////////////////////////////////////////////////////////////////////////////////////////////

// CLOSE BTN.
.modalCloseBtn {
  position: absolute;
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0;
  appearance: none;
  background: none;
  outline: none;

  &:before {
    display: inline-block;
    font-family: $nano-mdi-icons-font-family;
    font-size: 2.25rem;
    font-weight: $nano-font-weight-medium;
    color: $nano-dashboard-profile-modal-close-btn-color;
    content: "\F0159";

    @include vendor-prefix ('transition', 'all .375s ease');
  }

  &.largerResolutionsBtn {
    display: none;
    top: 12.5px;
    right: 12.5px;

    @media (min-width: $nano-xxxl-breakpoint) { display: block; }
  }

  &.smallerResolutionsBtn {
    top: 20px;
    right: 20px;
    z-index: 999;

    @media (min-width: $nano-xxxl-breakpoint) { display: none; }

  }

  @media (min-width: $nano-md-breakpoint) {
    &:hover {
      &:before { @include vendor-prefix ('transform', 'scale(1.175)'); }
    }
  }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

.modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  top: 0;
  left: 0;
  z-index: 100;
  overflow-x: hidden;
  background-color: $nano-dashboard-profile-modal-overlay-background-color;
  pointer-events: none;
  opacity: 0;
  overflow: hidden;

  @include vendor-prefix ('transition', 'opacity 250ms 700ms ease');

  &.visible {
    pointer-events: auto;
    opacity: 1;

    @include vendor-prefix ('transition', 'all 300ms ease-in-out');
  }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

.modal__wrap {
  position: relative;
  display: block;
  align-self: center;
  opacity: 0;
  width: 100%;
  max-width: 325px;
  height: auto;
  padding: $nano-space-md;
  transform: scale(0.6);
  transform: scale(0);
  background: $nano-dashboard-profile-modal-background-color;
  box-shadow: 0 12px 25px 0 rgba(199, 175, 189, 0.25);
  text-align: center;
  overflow: hidden;

  @include vendor-prefix ('border-radius', '8.5px');
  @include vendor-prefix ('transition', 'opacity 250ms 250ms ease, transform 300ms 250ms ease');

  // FLOATING CONTENT;
  .modalFloatingContent {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - 45px);

    @include vendor-prefix ('transform', 'translate(-50%, -50%)');
  }

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  @media (min-width: $nano-sm-breakpoint) { width: 325px; }

  @media (min-width: $nano-xxxl-breakpoint) {
    width: 100%;
    max-width: 425px;
  }

}

.modal__wrap {

  // MODAL TITLE.
  h3 {
    font-size: $nano-font-size-xxl;
    color: $nano-dashboard-profile-modal-font-color;
    margin: 0 0 $nano-space-sm 0;

    b {
      font-family: $nano-profile-complete-heading-font-family-2;
      text-decoration: underline;
    }

  }

  // MODAL COPY.
  p {
    font-size: $nano-font-size-base;
    color: $nano-dashboard-profile-modal-font-color;
    margin: 0 auto $nano-space-lg auto;
  }

  // BUTTON.
  .completeAccountModalBtn {
    @extend %nano-button;
    @include primary-button($nano-dashboard-modal-button-background-color, $nano-dashboard-modal-button-font-color);

    margin: 0;
    float: none;
  }

}

////////////////////////////////////////////////////////////////////////////////////////////////////

.visible .modal__wrap {
  opacity: 1;

  @include vendor-prefix ('transform', 'scale(1)');
  @include vendor-prefix ('transition', 'opacity 250ms 500ms ease, transform 350ms 500ms ease');

}

////////////////////////////////////////////////////////////////////////////////////////////////////
